#product-single-page {
    .bg-gray-stripe {
        padding-top: 70px;
        padding-bottom: 91px;
    }
    .related-recepies {
        &::before,
        &::after {
            display: none;
        }
    }
    .side-cta {
        width: 292px;
        position: fixed;
        // right: 36px;
        bottom: 5vh;
        transform: scale(0.75);
        right: -30px;
        z-index: 999;
        background: #fff;
        box-shadow: 0 15px 25px rgba(0, 0, 0, 0.22);

        .img-wrapper {
            @include borderRadius(50%);
            width: 197px;
            height: 197px;
            margin: -98px auto 0 auto;
            overflow: hidden;
            padding: 12px;
            background: #fff;
            img {
                width: 100%;
                height: 100%;
            }
        }

        .content {
            // height: 285px;
            padding: 33px 28px;
            h5 {
                color: $blue;
                font-family: $ptSerif;
                font-size: 30px;
                margin: 0 auto 36px auto;
                font-weight: $bold;
                font-style: italic;
            }
            a {
                padding: 0 15px;
                min-width: auto;
                display: block;
                height: 55px;
                line-height: 55px;
            }
        }
        form {
            display: none;
        }
        .form-msg {
            display: none;
        }
        &.form-opened {
            width: 700px;
            display: block;
            .img-wrapper {
                position: absolute;
                right: 60px;
                top: 0;
            }
            h5 {
                text-align: left;
                margin-bottom: 0;
            }
            form {
                text-align: left;
                textarea {
                    margin-top: 34px;
                    margin-bottom: 30px;
                    border: 1px solid #707070;
                    resize: none;
                    height: 108px;
                    font-family: $oswald;
                    font-weight: $light;
                    font-size: 25px;
                    color: #4e4e4e;
                    padding: 13px 9px;
                }
                button {
                    background: #fff;
                }
            }
            .form-msg {
                border: 2px solid #707070;
                padding: 15px;
                margin: 100px auto;
                font-family: $oswald;
                font-weight: $bold;
                font-size: 1.2rem;
                text-transform: uppercase;
                p {
                    color: inherit;
                    font-family: inherit;
                }
                &.error {
                    border-color: $red;
                    color: $red;
                }
                &.success {
                    border-color: #00661c;
                    color: #00661c;
                }
                &.warning {
                    border-color: #fec107;
                    color: #fec107;
                }
            }
        }
    }
}
.product-info {
    .img-wrapper {
        max-width: 750px;
        max-height: 500px;
        margin: 0 auto;
        padding-left: 120px;
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;
    }
    .product-description {
        .product-intro {
            margin-bottom: 50px;
            h3 {
                font-size: 40px;
                margin-bottom: 20px;
            }
            h4 {
                font-size: 35px;
                margin-bottom: 32px;
            }
            p {
                font-size: 25px;
                line-height: 1.48;
                font-weight: $light;
                color: #4e4e4e;
                margin: 0 auto;
                max-width: 47ch;
            }
        }
        .product-attr {
            margin-bottom: 90px;
            // font-family: $oswald;
            b,
            strong {
                font-weight: $bold;
            }
            // em {
            //     font-family: $ptSerif;
            //     font-style: italic;
            //     font-weight: inherit !important;
            // }
            h4 {
                font-size: 35px;
                max-width: 20ch;
                margin: 0 auto 25px auto;
            }
            p {
                font-size: 25px;
                // font-family: $oswald;
                margin-bottom: 1rem;
                b,
                strong {
                    font-weight: $bold;
                }
                // span {
                //     font-family: inherit !important;
                //     font: inherit !important;
                // }
            }
            ul {
                margin-bottom: 56px;
                &:last-of-type {
                    margin-bottom: 0;
                }
                li {
                    font-weight: $light;
                    font-size: 25px;
                    font-family: $oswald;
                    color: #4e4e4e;
                    margin-bottom: 22px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    span {
                        font-weight: $bold;
                    }
                }
            }
        }
    }
}
@media (max-width: 1440px) {
    .product-info .product-description {
        padding-right: 100px;
    }
    #product-single-page .side-cta {
        transform: scale(0.75);
        right: -30px;
    }
}
@media (max-width: 768px) {
    #product-single-page .side-cta {
        display: none;
    }
    #product-single-page {
        .p-xs-m {
            padding-top: 0 !important;
            margin-top: 2.5rem !important;
            .m-card {
                max-height: 400px;
                padding-bottom: 50px;
            }
            .m-mb-0 {
                margin-bottom: 0 !important;
            }
        }
    }
    .product-info {
        .img-wrapper {
            padding-left: 0;
            max-width: 270px;
            max-height: 368px;
            width: 100%;
            height: 100%;
        }
        .product-description {
            padding-right: 0;
            .product-intro {
                margin-bottom: 40px;
                h3 {
                    font-size: 25px;
                    margin-bottom: 5px;
                }
                h4 {
                    font-size: 20px;
                    margin-bottom: 10px;
                }
                p {
                    font-size: 20px;
                }
            }
            .product-attr {
                h4 {
                    font-size: 20px;
                    margin-bottom: 5px;
                }
                p {
                    font-size: 20px;
                }
                ul {
                    margin-top: 15px;
                    li {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    #product-single-page {
        .related-wrapper {
            .related-recepies {
                .section-title {
                    font-size: 25px;
                }
            }
            .recepie-card-wrapper {
                margin-bottom: 50px;
            }
        }
        .featured-blog-post {
            .section-title {
                font-size: 25px;
                max-width: 15ch;
                margin: 0 auto;
            }
        }
    }
}
