// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Font Usage
$oswald: "Oswald", sans-serif;
$ptSerif: "PT Serif", serif;

$light: 300;
$regular: 400;
$medium: 500;
$semiBold: 500;
$bold: 700;

// Colors
$blue: #004f8a;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #f50c1a;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$dBlue: #01253e;
