.certificate-text {
    p {
        font-size: 18px;
        color: #4e4e4e;
        margin-bottom: 25px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
.certs {
    img {
        cursor: pointer;
    }
}
.pdf-certs {
    margin-top: 55px;
    h3 {
        font-size: 35px;
        color: $dBlue;
        margin-bottom: 35px;
        font-family: $ptSerif;
    }
    ul {
        li {
            position: relative;
            padding: 0 15px;
            border-right: 2px solid $red;
            &:not(:last-child) {
                margin-right: 0;
            }
            &:last-of-type {
                border-right: none;
            }
            a {
                color: $red;
                font-size: 25px;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .certificate-text {
        p {
            text-align: center;
        }
    }
    .certs {
        text-align: center;
    }
    .pdf-certs{
        text-align: center;
    }
}
@media (max-width: 425px) {
    .pdf-certs {
        h3 {
            font-size: 25px;
        }
        ul {
            li {
                display: list-item;
                border-right: 0;
                text-align: center;
                margin-bottom: 15px;
                a {
                    font-size: 20px;
                }
            }
        }
    }
}
