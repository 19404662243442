#not-found-page {
    .not-found-wrapper {
        margin-bottom: 82px;
        .img-wrapper {
            max-width: 532px;
            margin: 26px auto 37px auto;
        }
        h4 {
            font-size: 41px;
            margin-bottom: 1rem;
        }
        p {
            font-size: 25px;
            margin: 0 auto 46px auto;
        }
    }
}
@media (max-width: 768px) {
    #not-found-page {
        .not-found-wrapper {
            h4 {
                font-size: 35px;
            }
            p {
                font-size: 20px;
                br {
                    display: none;
                }
            }
        }
    }
}
