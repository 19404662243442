.ad-baner {
    gap: 5px;
    h4 {
        font-size: 35px;
        margin: 0 auto;
        &:nth-of-type(1) {
            max-width: 15ch;
        }
    }
    .img-wrapper {
        margin: 0 auto;
        max-height: 300px;
        height: 100%;
        img {
            height: 100%;
            object-fit: contain;
        }
    }
    p {
        font-size: 25px;
    }
}
@media (max-width: 768px) {
    .ad-baner {
        margin-bottom: 42px;
        h4 {
            font-size: 20px;
            margin-bottom: 21px;
            &:nth-of-type(2) {
                margin-bottom: 0;
            }
        }
        .img-wrapper {
            max-width: 198px;
            max-width: 234px;
            margin: 0 auto 0.5em auto;
        }
        p {
            font-size: 20px;
            margin-top: 0;
        }
    }
}
