.opacity {

  &-03 {
    opacity: 0.3;
  }

  &-05 {
    opacity: 0.5;
  }

  &-10 {
    opacity: 1;
  }
}