.notification-card {
  text-align: center;
  .img-wrapper {
    width: 329px;
    height: 289px;
    margin: 0 auto;
    border-bottom: 2px dashed $dBlue;
    margin-bottom: 30px;
    img {
      object-fit: cover;
    }
  }
  h3 {
    font-size: 45px;
    font-family: $ptSerif;
    font-weight: $bold;
    font-style: italic;
    color: $red;
    margin-bottom: 9px;
  }
  p {
    font-size: 25px;
    font-family: $ptSerif;
    font-weight: $bold;
    font-style: italic;
    color: $dBlue;
    margin-bottom: 35px;
  }
}
