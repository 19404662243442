#news-letter {
    position: relative;
    padding: 40px 15px 100px 15px;
    // &::after {
    //     content: "";
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 212px;
    //     background: url("../../img/decoration/news-letter-after.svg");
    //     @include bg-props(contain, center center, no-repeat);
    //     z-index: -1;
    // }
    .privacy-info {
        label {
            font-weight: 300;
            font-family: "Oswald", sans-serif;
            font-size: 1rem;
            margin-bottom: 12px;
            padding-left: 30px;
            color: #4e4e4e;
        }
        p {
            padding-left: 30px;
            font-size: 15px;
            font-weight: 300;
            font-family: "Oswald", sans-serif;
            line-height: 1.5;
            margin-bottom: 16px;
            a {
                color: #f50c1a;
                text-decoration: underline;
                font-weight: 700;
            }
        }
        .form-check {
            padding-left: 30px;
            input {
                margin: 0;
                width: 19px;
                height: 19px;
                border-radius: 0;
                -moz-border-radius: 0;
                -webkit-border-radius: 0;
                border: 1px solid rgba(0, 0, 0, 0.25);
                &:checked {
                    border-color: #f50c1a;
                    background-color: #f50c1a;
                }
            }
            label {
                font-size: 15px;
                color: #4e4e4e;
                font-weight: 700;
                font-style: italic;
                &.form-check-label {
                    font-size: 15px;
                    color: #4e4e4e;
                    font-weight: 700;
                    font-style: italic;
                    font-family: $oswald;
                    padding-left: 14px;
                }
            }
        }
    }
    .content {
        text-align: center;
        .img-wrapper {
            width: 279px;
            height: 90px;
        }
        h4 {
            font-family: $ptSerif;
            font-size: 45px;
            line-height: 1.28;
        }
        h5 {
            font-family: $ptSerif;
            font-size: 25px;
            line-height: 1.28;
            margin-bottom: 50px;
        }
        input {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-bottom: 2px dashed $dBlue;
            font-size: 1rem;
            font-family: $ptSerif;
            font-style: italic;
            margin-bottom: 70px;
            margin-left: auto;
            margin-right: auto;
            max-width: 550px;
        }
    }
}
@media (max-width: 768px) {
    #news-letter .content {
        h4 {
            font-size: 1.5625rem;
            max-width: 15ch;
            margin: 0 auto;
        }
        h5 {
            font-size: 1.25rem;
        }
    }
    #news-letter .privacy-info {
        .form-check {
            padding-left: 0;
            input {
                position: absolute;
            }
            label {
                &.form-check-label {
                    padding-left: 24px;
                }
            }
        }
        p {
            padding-left: 0;
        }
    }
}
