.footer-before-deco {
    width: 100%;
    height: auto;
    position: relative;
    top: 30px;
    pointer-events: none;
}
footer {
    margin-top: 80px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 110px !important;
    padding-right: 110px !important;
    background: $dBlue;
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 160px;
        background: url("../../img/footer-before.svg");
        @include bg-props(104%, top, no-repeat);
        top: 0;
        left: 0;
        z-index: -1;
        transform: translate(0, -70%);
    }
    .logo-wrapper {
        max-width: 300px;
    }
    .top-row {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px dashed #004f8a;
        padding-bottom: 20px;
        margin-bottom: 20px;
        .icon-box,
        .logo-wrapper {
            height: 100%;
            flex: 1;
        }
        .icon-box {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            border-left: 1px dashed #004f8a;
            padding: 0 30px;
            &:last-of-type {
                padding: 0 0 0 30px;
            }
            a {
                &.do-btn-w {
                    min-width: fit-content;
                }
            }
        }
        .extra-links {
            width: fit-content;
            margin-left: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
            li {
                margin-right: 0;
                width: fit-content;
                display: block;
            }
        }
    }
    .bottom-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        .contact-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            align-content: center;
            flex-wrap: wrap;
            flex-direction: row;
            li {
                margin-bottom: 0;
                margin-right: 55px;
            }
        }
    }
    .contact-info {
        li {
            position: relative;
            padding-left: 48px;
            margin-bottom: 24px;
            color: #fff;
            font-size: 20px;
            font-family: $ptSerif;
            font-style: italic;
            line-height: 1.2;
            &:last-of-type {
                margin-bottom: 0;
            }
            &::before {
                position: absolute;
                content: "";
                left: 0;
                @include ver-center();
                width: 26px;
                height: 26px;
            }
            &.location {
                &::before {
                    background: url("../../img/icons/location.svg");
                    @include bg-props(contain, center, no-repeat);
                }
            }
            &.telephone {
                &::before {
                    background: url("../../img/icons/telephone.svg");
                    @include bg-props(contain, center, no-repeat);
                }
            }
            &.fax {
                &::before {
                    background: url("../../img/icons/fax.svg");
                    @include bg-props(contain, center, no-repeat);
                }
            }
            &.suit-case {
                &::before {
                    background: url("../../img/icons/suit-case.svg");
                    @include bg-props(contain, center, no-repeat);
                }
            }
        }
    }
    .footer-blocks {
        margin-top: 50px;
        border-bottom: 2px dashed #004f8a;
        padding-bottom: 42px;
        .footer-menu {
            li {
                margin-bottom: 35px;
                &:last-of-type {
                    margin-bottom: 0;
                }
                a {
                    color: #fff;
                    font-size: 22px;
                    font-weight: $bold;
                    font-style: italic;
                    font-family: $ptSerif;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .bordered-block {
            position: relative;
            &::before,
            &::after {
                content: "";
                position: absolute;

                @include ver-center();
                height: 100%;
                width: 2px;
                border-left: 2px dashed #004f8a;
            }
            &::before {
                left: 0;
            }
            &::after {
                right: 0;
            }
        }
        .footer-card {
            position: absolute;
            @include abs-center();
            width: 80%;
            height: 100%;
            h4 {
                font-size: 22px;
                line-height: 1.28;
            }
            a {
                position: absolute;
                @include hor-center();
                bottom: 0;
                min-width: 200px;
            }
        }
    }
    .footer-socials {
        li {
            margin-right: 20px;
            &:last-of-type {
                margin-right: 0;
            }
            a {
                position: relative;
                width: 50px;
                height: 50px;
                i {
                    @include abs-center();
                    position: absolute;
                    color: $dBlue;
                }
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    .extra-links {
        margin-left: auto;
        width: fit-content;
        li {
            display: inline;
            margin-right: 70px;
            &:last-of-type {
                margin-right: 0;
            }
            a {
                color: #fff;
                font-size: 22px;
                font-style: italic;
                font-family: $ptSerif;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
@media (max-width: 1440px) {
    footer .footer-blocks .footer-card h4 {
        br {
            display: none;
        }
    }
    footer {
        .top-row {
            .icon-box {
                .img-wrapper {
                    height: auto;
                    width: 55px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }
            }
        }
        .extra-links {
            li {
                a {
                    font-size: 14px;
                }
            }
        }
        .bottom-row {
            .contact-info,
            .footer-socials {
                flex: 1 1 auto;
            }
            .contact-info li {
                margin: 0 auto;
                padding-left: 30px;
                font-size: 14px !important;
            }
        }
    }
}
@media (max-width: 1320px) {
    footer {
        padding-left: 55px !important;
        padding-right: 55px !important;
        &::before {
            transform: translate(0, -55%);
        }
    }
}
@media (max-width: 1024px) {
    footer {
        .bottom-row {
            flex-direction: column;
            .footer-socials {
                margin-bottom: 25px;
            }
        }
    }
}
@media (max-width: 768px) {
    .footer-before-deco {
        top: -60px;
    }
    footer {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        text-align: center;
        margin-top: 0;
        &::before {
            transform: translate(0, -40%);
        }
        .top-row {
            flex-direction: column;
            .icon-box {
                margin-bottom: 25px;
                border: none !important;
                flex-direction: column;
                .img-wrapper {
                    margin-bottom: 15px;
                }
            }
        }
        .bottom-row {
            ul {
                &.contact-info {
                    display: block;
                    li {
                        margin-bottom: 25px;
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .contact-info {
            li {
                font-size: 1.125rem !important;
            }
        }
        .logo-wrapper {
            margin: 0 auto;
        }
        .contact-info {
            text-align: left;
            width: fit-content;
            margin: 30px auto;
        }
        .footer-blocks {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 40px;
            border-top: 2px dashed #004f8a;
            border-bottom: none;
            padding-top: 35px;
            .footer-card {
                position: relative;
                top: 0%;
                left: 0%;
                transform: translate(0, 0);
                -moz-transform: translate(0, 0);
                -webkit-transform: translate(0, 0);
                margin: 0 auto;
                width: 100%;
                padding-top: 22px;
                padding-bottom: 22px;
                a {
                    position: relative;
                    left: auto;
                    transform: translate(0%);
                    -moz-transform: translate(0%);
                    -webkit-transform: translate(0%);
                    bottom: auto;
                }
                &:last-of-type {
                    margin: 0 auto;
                    border-bottom: 2px dashed $blue;
                }
            }
            .bordered-block {
                margin: 0 auto;
                // border-top: 2px dashed $blue;
                // border-bottom: 2px dashed $blue;
                &::before,
                &::after {
                    display: none;
                }
            }
        }
        .footer-socials li,
        .extra-links li {
            margin-right: 0;
            margin-bottom: 20px;
        }
        .footer-socials {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around !important;
            align-items: center;
            align-content: center;
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;
            li {
                &:last-of-type {
                    margin-right: 0;
                }
                a {
                    display: inline-block;
                }
            }
        }
        .extra-links {
            width: 100%;
            li {
                display: block;
            }
        }
    }
}
@media (max-width: 425px) {
    .footer-before-deco {
        top: -30px;
    }
    footer {
        &::before {
            transform: translate(0, -20%);
            top: 5px;
        }
        .contact-info {
            text-align: left;
            width: 90%;
        }
        .top-row {
            .extra-links {
                display: flex;
                justify-content: space-between;
                width: 100%;
                flex-direction: column;
                align-items: center;
            }
            .icon-box {
                &:last-of-type {
                    padding-left: 0;
                }
            }
        }
        .bottom-row {
            .footer-socials {
                justify-content: space-evenly !important;
                width: 100%;
            }
        }
    }
}
@media (max-width: 1440px) {
    footer {
        .contact-info li {
            font-size: 1rem !important;
        }
        .footer-blocks {
            .footer-menu li a,
            .footer-card h4 {
                font-size: 1.125rem !important;
            }
        }
    }
}
