#vide-slider-page {
    .video-player {
        .img-wrapper {
            position: absolute;
            width: 15vw;
            height: auto;
            z-index: -1;

            &:first-of-type {
                left: 8vw;
                bottom: 0;
                transform: rotate(-6deg);
                -webkit-transform: rotate(-6deg);
                -moz-transform: rotate(-6deg);
            }

            &:last-of-type {
                bottom: 2rem;
                right: 7vw;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
    }

    .video-wrapper {
        max-width: 55vw;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }
}

@media (max-width:425px) {
    #vide-slider-page {
        .video-wrapper {
            max-width: 100%;
        }
        .video-player {
            .img-wrapper {
                display: none;
            }
        }
    }
}