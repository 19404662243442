.hero-wrapper {
    .svg-bg-slider {
        top: 0;
        left: 0;
        z-index: -1;
    }
}
.slick-arrow {
    &:hover {
        cursor: pointer;
    }
}
.hero {
    // min-height: 1;
    position: relative;
    top: -50px;
    // &::before {
    //     position: absolute;
    //     content: "";
    //     background: url("../../img/decoration/nav-after.svg");
    //     background-position: center center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     width: 100%;
    //     height: 196px;
    //     left: 0;
    //     top: 52px;
    //     z-index: 99;
    // }
    &.full-width {
        height: 100%;
        // min-height: 95vh;
        padding-bottom: 100px;
        .slick-list,
        .slick-track {
            height: 100%;
            display:flex;
            align-items: center;
        }
        .slick-track{
            display: flex!important;
        }
        .slick-arrow {
            position: absolute;
            @include ver-center();
            top: calc(50% + 50px);
            z-index: 999;
            width: 80px;
            height: 80px;
            &:nth-of-type(1) {
                left: 25px;
            }
            &:nth-of-type(2) {
                right: 25px;
            }
        }

        .single-slide {
            .slide-content {
                color: #01253e;
                .img-wrapper {
                    width: 140px;
                    height: 98px;
                    margin-bottom: 20px;
                    display: block;
                    margin-top: 20px;
                    margin-left: auto;
                    margin-right: auto;
                    img {
                        object-fit: contain;
                        margin-left: auto;
                        margin-right: auto;
                        width: 100%;
                        height: 100%;
                    }
                }
                h4 {
                    color: $red;
                    text-align: center;
                    font-size: 45px !important;
                    font-weight: $bold;
                    font-style: italic;
                    font-family: $ptSerif;
                    line-height: 1.28;
                    margin-bottom: 14px;
                }
                .red-text-separator {
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        left: -150px;
                        @include ver-center();
                        background: url("../../img/shape-div-red-left.svg");
                        @include bg-props(contain, center center, no-repeat);
                        width: 154px;
                        height: 8px;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        right: -150px;
                        @include ver-center();
                        background: url("../../img/shape-div-red-right.svg");
                        @include bg-props(contain, center center, no-repeat);
                        width: 154px;
                        height: 8px;
                    }
                }
                h3 {
                    font-size: 70px;
                    font-weight: $bold;
                    font-family: $oswald;
                    margin-bottom: 30px;
                }
                p {
                    font-size: 40px;
                    font-family: $ptSerif;
                    max-width: 20ch;
                    margin: 0 auto;
                    line-height: 1;
                }
                a.do-btn-w {
                    display: block;
                    min-width: 238px;
                    width: fit-content;
                    margin: 0 auto;
                    background: #fff;
                }
            }
            .home-slider-img-wrapper {
                width: 70%;
                height: 100%;
                display: block;
                margin: auto;
                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
.m-slider {
    .slick-arrow {
        position: absolute;
        @include ver-center();
        width: 70px;
        height: 70px;
        z-index: 9;
        padding: 0 12px;
        &.slick-prev {
            left: 0;
        }
        &.slick-next {
            right: 0;
        }
    }
}
#recepie-slider {
    height: 900px;
    background: url("../../img/slider-bg.svg");
    padding-top: 3rem;
    @include bg-props(cover, top center, no-repeat);
    position: relative;
    &::after {
        content: "";
        position: absolute;
        background: url("../../img/curve-after.svg");
        @include bg-props(contain, center center, no-repeat);
        width: 100%;
        height: 153px;
        bottom: 0;
        left: 0;
    }

    .slick-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        & > div {
            width: 100%;
        }
    }
}
.ml-25 {
    margin-left: 25% !important;
}
.mr-25 {
    margin-right: 25% !important;
}
@media (max-width: 1440px) {
    .hero {
        &::before {
            top: 43px;
            height: 157px;
        }
        &.full-width {
            // max-width: 85%;
            margin-left: auto;
            margin-right: auto;
            .slick-arrow {
                width: 50px;
                height: 50px;
            }
        }
    }
}
@media (max-width: 1366px) {
    .hero {
        &::before {
            top: 34px;
        }
    }
}
@media (max-width: 1040px) {
    .hero {
        &::before {
            top: 35px;
            height: 106px;
        }
    }
    .ml-25,
    .mr-25 {
        margin: auto !important;
    }
}
@media (max-width: 768px) {
    #recepie-slider {
        height: auto;
        background-attachment: fixed;
        padding-bottom: 50px;
        background: #f4f4f4;
        padding-top: 1px;
        .remove-pt {
            padding-top: 5px !important;
        }
        &::after {
            bottom: -63px;
        }
        &::before {
            content: "";
            position: absolute;
            top: -30px;
            left: 0;
            width: 100%;
            height: 45px;
            background: url("../../img/decoration/recepie-slider-before.svg");
            @include bg-props(contain, center, no-repeat);
        }
    }
    .hero {
        padding-top: 150px;
        // top: 100px;
        &::before {
            display: none;
        }
    }
    // .hero-wrapper {
    //     min-height: 110vh;
    // }
    .hero-wrapper {
        .svg-bg-slider {
            display: none;
        }
    }
    .hero.full-width {
        background: url("../../img/slide-bg.svg");
        @include bg-props(cover, center, no-repeat);
        padding-bottom: 25px;
        top: 0;
        margin-bottom: 25px;
        // height: 375px;
        // margin-top: 95px;
        // margin-bottom: 20px;
        // .single-slide {
        //     .slide-content {
        //         width: fit-content;
        //         .img-wrapper {
        //             width: 160px;
        //         }
        //         h3 {
        //             font-size: 2.0625rem;
        //         }
        //         p {
        //             font-size: 1.125rem;
        //         }
        //         .red-text-separator {
        //             &::before {
        //                 left: -105px;
        //             }
        //             &::after {
        //                 right: -105px;
        //             }
        //             &::before,
        //             &::after {
        //                 width: 114px;
        //                 height: 100%;
        //             }
        //         }
        //     }
        .home-slider-img-wrapper {
            max-width: 306px;
            width: 100%;
            margin: 25px auto 0 auto;
            display: block;
            margin-top: 35px !important;
        }
        .single-slide {
            .slide-content {
                .img-wrapper {
                    height: auto;
                    margin: 0 auto 1px auto;
                }
            }
        }
        // }
        .slick-track {
            display: flex;
            margin-left: auto;
            margin-right: auto;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
        }
    }
    .widget-wrapper {
        .slick-slide {
            opacity: 0.5;
            transition: 0.6s;
            // margin: 0 20vw 0 0;
            &.slick-center {
                opacity: 1;
            }
        }
    }
    .m-slider {
        .slick-slide {
            opacity: 1;
            transition: 0.6s;
            &:not(.slick-active) {
                opacity: 0;
                transition: 0.6s;
            }
        }
    }
}
@media (max-width: 425px) {
    #heroBackground-svg {
        display: none;
    }
    .hero.full-width {
        max-width: 100%;
        margin-bottom: 5px;

        .slick-arrow {
            &.slick-prev {
                left: 1px;
            }
            &.slick-next {
                right: 1px;
            }
        }
        .single-slide {
            padding-left: 30px;
            padding-right: 30px;
            .home-slider-img-wrapper {
                max-width: 236px;
                margin: auto;
                height: fit-content;
            }
            .slide-content {
                .red-text-separator {
                    &::before {
                        left: -55px;
                    }
                    &::after {
                        right: -55px;
                    }
                    &::before,
                    &::after {
                        width: 54px;
                        height: 100%;
                    }
                }
                p {
                    [style*="font-size: 35px"] {
                        font-size: 25px !important;
                    }
                    [style*="font-size: 25px"] {
                        font-size: 18px !important;
                    }
                }
            }
        }
    }
}
@media (max-width: 320px) {
    .hero.full-width {
        .single-slide {
            .slide-content {
                .red-text-separator {
                    &::before {
                        left: -25px;
                    }
                    &::after {
                        right: -25px;
                    }
                    &::before,
                    &::after {
                        width: 35px;
                        height: 100%;
                    }
                }
            }
        }
    }
}
