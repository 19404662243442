#confirmation-page {
    .img-wrapper {
        width: 100%;
        max-width: 483px;
        margin: 0 auto 29px auto;
    }
    h3 {
        text-align: center;
        color: $red;
        font-style: italic;
        font-family: $ptSerif;
        font-weight: $bold;
        font-size: 41px;
        margin-bottom: 1rem;
    }
    p {
        color: $dBlue;
        font-family: $ptSerif;
        font-size: 25px;
        font-weight: $bold;
        font-style: italic;
        margin: 0 auto 45px auto;
        max-width: 44ch;
        text-align: center;
    }
}
