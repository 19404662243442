#promo-cta {
    .featured-img {
        width: 530px;
        height: 500px;
        margin: 0 auto;
        img {
            object-fit: cover;
            object-position: center;
        }
    }
    .promo-content {
        position: absolute;
        width: 100%;
        @include abs-center();
        &::before {
            content: "";
            position: absolute;
            width: 2px;
            height: 100%;
            @include ver-center();
            left: -20px;
            border-left: 2px dashed $dBlue;
        }
        h4 {
            font-size: 45px;
            line-height: 1.28;
            margin-bottom: 9px;
            font-family: $ptSerif;
        }
        h5 {
            font-size: 25px;
            line-height: 1.28;
            margin-bottom: 29px;
            font-family: $ptSerif;
        }
        p {
            font-size: 22px;
            font-family: $ptSerif;
            max-width: 52ch;
            margin: 0 auto;
        }
    }
}
@media (max-width: 1024px) {
    #promo-cta {
        margin-bottom: 50px;
        .featured-img {
            width: 300px;
            height: 300px;
            margin: 0 auto;
        }
        .promo-content {
            position: relative;
            width: 100%;
            top: 0%;
            left: 0%;
            transform: translate(0%, 0%);
            -moz-transform: translate(0%, 0%);
            -webkit-transform: translate(0%, 0%);
            &::before {
                width: 100%;
                height: 2px;
                top: -14px;
                left: 0%;
                transform: translate(0%, 0%);
                -moz-transform: translate(0%, 0%);
                -webkit-transform: translate(0%, 0%);
                border-left: 0;
                border-top: 2px dashed $dBlue;
            }
        }
    }
}
@media (max-width: 768px) {
    #promo-cta {
        .featured-img {
            width: 280px;
            height: auto;
        }
        .promo-content {
            margin-top: 38px;
            &::before {
                width: 90%;
                @include hor-center();
                top: -24px;
            }
        }
    }
}
@media (max-width: 425px) {
    #promo-cta {
        .promo-content {
            h4 {
                font-size: 1.5625rem;
            }
            h5 {
                font-size: 1.25rem;
            }
            p {
                display: none;
            }
        }
    }
}
