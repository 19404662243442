.related-recepies {
    position: relative;
    min-height: 1000px;
    padding-top: 80px;
    .section-title {
        margin-top: 120px;
        margin-bottom: 65px;
        padding: 0 100px;
    }
    .related-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        .recepie-card-wrapper {
            margin-right: 70px;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 153px;
        background: url("../../img/decoration/related-after.svg");
        @include bg-props(cover, center, no-repeat);
        left: 0;
        top: 0;
        @include rotate(180);
    }
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 153px;
        background: url("../../img/decoration/related-after.svg");
        @include bg-props(cover, center, no-repeat);
        left: 0;
        bottom: 0;
    }
}

@media (max-width: 768px) {
    .related-recepies {
        min-height: auto;
        padding: 80px 15px 25px 15px;
        margin-top: 100px;
        &::before,
        &::after {
            display: none;
        }
        .section-title {
            margin-top: -140px;
            margin-bottom: 95px;
            font-size: 20px;
            padding: 0;
        }
        .related-wrapper {
            display: block;
            .recepie-card-wrapper {
                margin-right: auto;
                margin-bottom: 45px;
                &:last-of-type {
                    margin-bottom: 0;
                    margin-right: auto;
                }
            }
        }
    }
}
