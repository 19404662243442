.blog-listing {
    margin-bottom: 100px;
    padding-right: 0;
    .on-top-posts {
        z-index: 99;
        position: relative;
        right: -140px;
    }
    .bellow-posts {
        overflow-x: hidden;
        padding-right: 0;
        margin-left: -1.2rem;
        width: calc(50% + 1.2rem);
    }
}
.blog-single-wrapper {
    padding-left: 140px;
    padding-right: 125px;
    .featured-img {
        width: 100%;
        height: 590px;
        margin-bottom: 86px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .blog-content {
        margin-bottom: 240px;
        ul {
            list-style: circle;
        }
        p {
            font-size: 18px;
            font-weight: $regular;
            color: #4e4e4e;
            line-height: 1.56666667;
            margin-bottom: 60px;
            &:last-of-type {
                margin-bottom: 0;
            }
            b,
            strong {
                font-weight: $bold;
            }
            a {
                color: $red;
                &:hover {
                    text-decoration: underline;
                }
            }
            ul {
                list-style: circle;
            }
        }
    }
    .related-blog-posts {
        .blog-card {
            &.simple-card {
                h3 {
                    max-width: 18ch;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 25px;
                }
            }
        }
    }
}
.blog-header {
    margin-bottom: 75px;
    h1 {
        color: $red;
        font-size: 45px;
        font-weight: $bold;
        font-style: italic;
        font-family: $ptSerif;
        margin: 0 auto 1rem auto;
        max-width: 30ch;
    }
    h4 {
        font-size: 25px !important;
        font-weight: $bold;
        text-transform: uppercase;
        color: $dBlue;
        font-family: $oswald;
    }
}
.blog-card {
    margin-top: 143px;
    font-family: $ptSerif;
    h4 {
        font-size: 20px;
        margin-bottom: 27px;
    }
    .img-wrapper {
        width: 100%;
        height: 520px;
        margin-bottom: 30px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    h3 {
        font-size: 30px;
        margin-bottom: 13px;
    }
    p {
        font-size: 25px;
        color: $dBlue;
        font-family: $ptSerif;
        font-style: italic;
        font-weight: $bold;
        margin-bottom: 40px;
    }
    &.simple-card {
        padding: 75px 250px 75px 200px;
        z-index: -1;
        min-height: 493px;
        margin-top: 0;
        h3 {
            line-height: 1.28888889;
            margin-top: 83px;
            position: relative;
            margin-bottom: 42px;
            max-width: 18ch;
            margin-left: auto;
            margin-right: auto;

            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 3px;
                top: -41.5px;
                left: 0;
                border-top: 2px dashed $dBlue;
            }
        }
        a {
            min-width: 269px;
        }
        &__promo {
            margin-top: 125px;
            .img-wrapper {
                max-width: 432px;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            h3 {
                font-size: 35px;
                max-width: 19ch;
                margin-left: auto;
                margin-right: auto;
                margin-top: 83px;

                margin-bottom: 42px;
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    width: 70%;
                    height: 3px;
                    top: -41.5px;
                    left: 0;
                    border-top: 2px dashed $dBlue;
                    @include hor-center();
                }
            }
            a {
                min-width: 269px;
            }
        }
    }
}
@media (max-width: 1440px) {
    .blog-single-wrapper {
        padding-left: 90px;
        padding-right: 90px;
    }
    .blog-card {
        h3 {
            font-size: 30px;
        }
        &.simple-card {
            padding: 75px 130px 75px 200px;
        }
        .img-wrapper {
            height: 420px;
        }
    }
    .blog-listing .on-top-posts {
        right: -100px;
    }
    .related-blog-posts {
        padding-left: 50px;
    }
    #blog-single-page {
        .blog-card.simple-card {
            padding-top: 0;
            padding-bottom: 0;
            min-height: 450px;
        }
    }
}
@media (max-width: 1024px) {
    .blog-single-wrapper {
        padding-left: 20px;
        padding-right: 20px;
        .featured-img {
            height: auto;
        }
    }
    .blog-listing .on-top-posts {
        right: -50px;
    }
    .blog-card {
        &.simple-card {
            padding: 75px 60px 75px 100px;
        }
        h3 {
            font-size: 32px;
            padding: 0 15px;
        }
        p {
            font-size: 20px;
            padding: 0 15px;
        }
    }
}
@media (max-width: 768px) {
    .blog-single-wrapper {
        margin-bottom: 15px;
        .blog-content {
            margin-bottom: 15px;
        }
    }

    .blog-listing {
        padding-left: 0;

        .bellow-posts {
            width: 100%;
            margin-left: 0;
            padding-left: 0;
            .col-12 {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .on-top-posts {
            right: 0;
            padding-left: 0;
            padding-right: 0;
        }
        .col-lg-12 {
            padding-left: 0;
            padding-right: 0;
        }
        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .blog-card.simple-card {
        margin-top: 25px;
    }
    .blog-card {
        text-align: center;
        margin-top: 64px;
        h4 {
            text-align: center;
            margin-bottom: 17px;
        }
        &.simple-card {
            padding: 65px 30px;
            min-height: auto;
            &:nth-of-type(1) {
                margin-top: 47px;
            }
            h4 {
                margin-bottom: 0;
            }
            h3 {
                font-size: 20px;
                margin-top: 27px;
                margin-bottom: 33px;
                &::before {
                    top: -13.5px;
                }
            }
            &__promo {
                display: none;
            }
        }
    }
    .related-blog-posts {
        padding-left: 0;

        .order-m-first {
            order: 1;
        }
        .order-m-second {
            order: 2;
        }

        .m-card .content h3 {
            margin-top: -86px;
            margin-bottom: 43px;
        }

        .blog-header {
            margin-bottom: 35px;
            .featured-img {
                margin-bottom: 25px;
            }
            h1 {
                font-size: 30px;
            }
            h4 {
                font-size: 20px;
            }
        }
        .blog-single-wrapper {
            padding-left: 0;
            padding-right: 0;
            .blog-content {
                margin-bottom: 35px;
                p {
                    font-size: 15px;
                    line-height: 1.2;
                    margin-bottom: 25px;
                }
            }
        }
        .blog-card.simple-card a {
            min-width: auto;
        }
    }
    #blog-single-page .blog-card.simple-card {
        min-height: auto;
        margin-bottom: 50px;
        &:last-of-type {
            margin-bottom: 15px;
        }
    }
}
@media (max-width: 475px) {
    .blog-card.simple-card {
        padding: 28px 30px;
    }
    .blog-single-wrapper {
        .blog-card.simple-card {
            &:nth-of-type(1) {
                margin-top: 0;
            }
        }
    }
}
