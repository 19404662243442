.categories-listing {
    .widget-wrapper {
        position: relative;
        &::before {
            position: absolute;
            content: "";
            background: url("../../img/icons/shape-div.svg");
            @include bg-props(contain, center, no-repeat);
            width: 136px;
            height: 7.77px;
            @include ver-center();
            left: -135px;
        }
        &::after {
            position: absolute;
            content: "";
            background: url("../../img/icons/shape-div-right.svg");
            @include bg-props(contain, center, no-repeat);
            width: 136px;
            height: 7.77px;
            @include ver-center();
            right: -135px;
        }
    }
    .cat-card {
        width: fit-content;
        margin: 0 auto;
        text-align: center;
        .img-wrapper {
            width: 148px;
            height: 115px;
            margin: 0 auto;
            img {
                object-fit: contain;
            }
        }
        p {
            font-family: $ptSerif;
            font-weight: $bold;
            font-style: italic;
            font-size: 18px;
            color: $red;
            text-align: center;
            max-width: 15ch;
            margin: 0 auto;
        }
    }
}
@media (max-width: 1440px) {
    .categories-listing {
        max-width: 1000px;
        .cat-card {
            .img-wrapper {
                width: 110px;
                height: 110px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    .categories-listing .widget-wrapper {
        &::before,
        &::after {
            display: none;
        }
        .cat-card {
            .img-wrapper {
                width: 68px;
                height: auto;
                min-height: 83px;
                margin: 0 auto;
            }
        }
    }
}
@media (max-width: 768px) {
    .categories-listing {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        .cat-card {
            margin: 0 auto 35px auto;
        }
    }
}
@media (max-width: 375px) {
    .categories-listing .cat-card p {
        font-size: 18px;
    }
}
.category-item {
    opacity: 0.3 !important;
    &[data-active="true"] {
        opacity: 1 !important;
    }
}
