.cta-recepies {
    .cat-wrap {
        width: fit-content;
        margin: 0 auto;
        position: relative;
        // left: -25px;
        /* the slides */
        .slick-slide {
            margin: 0 49px;
        }
        /* the parent */
        .slick-list {
            margin: 0 -49px;
        }
        .slick-slide {
            // margin-right: 98px;
            white-space: normal;
            // &:last-of-type {
            //     margin-right: 0;
            // }
            .img-wrapper {
                height: 96px;
                height: 88px;
            }
            .cat-title {
                font-family: $oswald;
                font-size: 20px;
                max-width: 15ch;
                margin: 0 auto;
            }
        }
    }
}
// @media (max-width: 1440px) {
//     .cta-recepies {
//         .list-inline {
//             li.list-inline-item {
//                 margin-right: 60px;
//                 &:last-of-type {
//                     margin-right: 0;
//                 }
//             }
//         }
//     }
// }

@media (max-width: 768px) {
    .cta-recepies {
        // ul.list-inline {
        //     li {
        //         &.list-inline-item {
        //             margin-right: -0;
        //             &:last-of-type {
        //                 margin-right: -0;
        //             }
        //         }
        //         &.slick-slide {
        //             // opacity: 0.5;
        //             transition: 0.6s;
        //             margin: 0 20vw 0 0;

        //             &.slick-center {
        //                 opacity: 1;
        //             }
        //         }
        //     }
        // }
        max-width: 100%;
        // padding: 0;
        // margin: 0;
        .list-inline li.list-inline-item {
            margin-right: 0;
            transition: 0.6s;
            opacity: 1;
            &:not(.slick-active) {
                opacity: 0;
                transition: 0.6s;
            }
        }
        .cat-wrap {
            overflow-x: hidden;
        }
    }
}

@media (max-width: 425px) {
    .cta-recepies {
        .img-wrapper {
            width: 50px;
            height: 50px !important;
            margin: 0 auto;
        }
        .cat-title {
            font-size: 1rem;
        }
    }
    // .cta-recepies {
    //     ul.list-inline {
    //         li {
    //             &.list-inline-item {
    //                 margin-right: -0;
    //                 &:last-of-type {
    //                     margin-right: -0;
    //                 }
    //             }
    //             &.slick-slide {
    //                 // opacity: 0.5;
    //                 transition: 0.6s;
    //                 margin: 0 10vw 0 0;
    //             }
    //         }
    //     }
    // }
}
