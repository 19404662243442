.contact-page {
    form {
        label {
            font-weight: 300;
            font-family: Oswald, sans-serif;
            font-size: 20px;
            margin-bottom: 12px;
            padding-left: 30px;
            color: #4e4e4e;
            display: block;
            text-transform: uppercase;
        }
        input {
            border: 2px solid #f50c1a;
            margin-bottom: 18px;
            border-radius: 30px;
            padding: 8px 30px;
            height: 55px;
            font-size: 25px;
            font-weight: 300;
            color: #4e4e4e;
            font-family: Oswald, sans-serif;
            width: 100%;
        }
        textarea {
            resize: none;
            width: 100%;
            min-height: 150px;
            border: 2px solid #f50c1a;
            margin-bottom: 18px;
            border-radius: 30px;
            padding: 8px 30px;
            font-size: 25px;
            font-weight: 300;
            color: #4e4e4e;
            font-family: Oswald, sans-serif;
        }
    }
    .contact-info {
        margin-top: 55px;
        ul {
            width: fit-content;
            margin: 0 auto;
            li {
                position: relative;
                padding-left: 48px;
                margin-bottom: 24px;
                color: $dBlue;
                font-size: 20px;
                font-family: "PT Serif", serif;
                font-style: italic;
                line-height: 1.2;
                max-width: 29ch;
                &::before {
                    position: absolute;
                    content: "";
                    left: 0;
                    @include ver-center();
                    width: 26px;
                    height: 26px;
                }
                &.location {
                    &::before {
                        background: url("../../img/icons/location.svg");
                        @include bg-props(contain, center, no-repeat);
                    }
                }
                &.telephone {
                    &::before {
                        background: url("../../img/icons/telephone.svg");
                        @include bg-props(contain, center, no-repeat);
                    }
                }
                &.fax {
                    &::before {
                        background: url("../../img/icons/fax.svg");
                        @include bg-props(contain, center, no-repeat);
                    }
                }
                &.suit-case {
                    &::before {
                        background: url("../../img/icons/suit-case.svg");
                        @include bg-props(contain, center, no-repeat);
                    }
                }
                &.envelope {
                    &::before {
                        background: url("../../img/icons/envelope-solid.svg");
                        @include bg-props(contain, center, no-repeat);
                    }
                }
            }
        }
    }
}
