#stores-page {
    .map,
    .stores-listing {
        height: 500px;
    }

    .stores-listing {
        overflow-y: scroll;

        .store-wrapper {
            padding: 10px;

            .data {
                padding-left: 10px;
                gap: 10px;

                h3 {
                    font-size: 18px  !important;
                }

                p {
                    font-size: 18px  !important;
                    font-weight: $light;
                    color: #4e4e4e;
                }
            }
        }
    }

    .info {
        h3 {
            font-size: 45px;
        }

        h4 {
            font-size: 25px;
        }
    }

    form {
        &.filter {
            input {
                background: url("../../img/icons/locator.svg");
                background-repeat: no-repeat;
                background-position: 1%;

                &.form-control {
                    @include borderRadius(30px);
                    border: 2px solid $red;
                    margin-bottom: 25px;
                    height: 55px;
                    font-size: 25px;
                    font-weight: $light;
                    color: #4e4e4e;
                    padding: 8px 45px;
                    font-family: $oswald;
                }
            }

            a {
                height: 55px;
                line-height: 1.5;
            }
        }
    }
    .map{
        h3{
            font-size: 20px;
        }
        p{
            font-size: 15px;
            font-weight: 300;
            color: #4e4e4e;
            margin: 5px 0;
        }
        .leaflet-popup{
            margin-bottom: 40px;
        }
        .leaflet-popup-content-wrapper{
            padding: 20px;
            .leaflet-popup-content{
              margin: 0;
            }
        }
       
    }
}


@media (max-width: 1024px) {
    #stores-page .stores-listing .store-wrapper {
        img {
            width: 36px;
        }
    }
}
@media (max-width: 768px) {
    #stores-page {
        .info {
            h3 {
                font-size: 35px;
            }

            h4 {
                font-size: 20px;
            }
        }

        .stores-listing {
            overflow-y: scroll;

        }
    }
}
