.related-products {
    .section-header {
        margin-bottom: 88px;
        margin-top: 77px;
    }
    .product-card {
        .category {
            margin-bottom: 78px;
            .img-wrapper {
                max-width: 130px;
                margin: 0 auto 14px auto;
            }
            a {
                margin: 29px auto;
            }
        }
        .img-wrapper {
            margin: 0 auto;
            max-width: 365px;
            max-height: 365px;
        }
    }
    .category .title,
    .product .title {
        margin-bottom: 1rem;
    }
}
@media (max-width: 768px) {
    .related-products {
        .row-wrapper {
            margin-left: auto;
            margin-right: auto;
        }
        .section-title {
            font-size: 25px;
        }
        .section-header {
            margin-top: 48px;
            margin-bottom: 34px;
        }
        .product-card {
            .category {
                position: relative;
                padding-bottom: 38px;
                margin-bottom: 38px;
                &::after {
                    content: "";
                    position: absolute;
                    width: 70%;
                    height: 3px;
                    border-top: 2px dashed $dBlue;
                    bottom: 0;
                    @include hor-center();
                }
                .img-wrapper {
                    max-width: 130px;
                    max-height: 115px;
                }
                h4 {
                    max-width: 20ch;
                    margin: 0 auto;
                }
                a {
                    margin: 20px auto;
                }
            }
        }
        .category .title,
        .related-products .product .title {
            min-height: 65px;
        }
    }
}
