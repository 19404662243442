.m-card {
    min-height: 700px;
    position: relative;
    height: 100%;

    &::before {
        content: "";
        position: absolute;
        background: url("../../img/decoration/mountain-deco.svg");
        @include bg-props(contain, center, no-repeat);
        bottom: 0;
        @include hor-center();
        width: 50%;
        height: 140px;
    }
    &.no-after {
        &::before {
            display: none;
        }
    }
    .content {
        width: 70%;
        position: absolute;
        @include abs-center();

        &::before {
            content: "";
            position: absolute;
            width: 60%;
            height: 1px;
            top: -40px;
            @include hor-center();
            border-top: 2px dashed #fff;
        }
        h3 {
            font-size: 20px;
            font-weight: $bold;
            font-family: $oswald;
            text-transform: uppercase;
            margin-top: -100px;
            margin-bottom: 90px;
            color: #fff;
        }
        p {
            font-size: 25px;
            font-family: $ptSerif;
            font-weight: $bold;
            font-style: italic;
            color: #fff;
            text-align: center;
            max-width: 18ch;
            margin: 0 auto 60px auto;
        }

        a {
            display: block;
            margin: 0 auto;
            width: fit-content;
            font-size: 18px;
        }
    }

    &.blue {
        background: $dBlue;
    }

    &.red {
        background: $red;
    }

    &.white {
        background: #fff;

        &::before {
            display: none;
        }

        .content {
            h6 {
                font-family: $oswald;
                color: $dBlue;
                font-size: 18px;
                font-weight: $bold;
                margin-bottom: 88px;
                text-transform: uppercase;
                position: relative;

                img {
                    position: absolute;
                    height: 145px;
                    left: -60px;
                    top: -50px;
                }
            }

            h4 {
                color: $red;
                font-size: 25px !important;
                font-family: $ptSerif;
                font-weight: $bold;
                font-style: italic;
                margin-bottom: 20px;
            }

            p {
                font-size: 18px;
                color: $dBlue;
                font-family: $ptSerif;
                font-weight: $bold;
                font-style: italic;
                max-width: 36ch;
            }

            &::before {
                border-top: 2px dashed $dBlue;
                top: 60px;
            }
        }
    }
}

.cat-cards {
    .img-wrapper {
        width: 100%;
        height: 100%;

        img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            max-width: 100%;
        }
    }
}
.border-white {
    border: 10px solid white;
    background: white;
    &.shadow {
        @include box-shadow(20px 23px 26px rgb(0 0 0 / 20%));
    }
}
// Style category for component
.category,
.product {
    gap: 15px;

    .title {
        font-size: 2rem;
        font-family: $ptSerif;
        font-style: italic;
    }

    .do-btn-w {
        width: 100%;
        max-width: 169px;
    }
    .weight {
        font-size: 25px;
    }
}
.product-hover {
    transition: 0.6s;
    &:hover {
        background: white;
        @include box-shadow(10px 13px 16px rgb(0 0 0 / 20%));
    }
}

@media (max-width: 1440px) {
    .m-card {
        .content {
            p {
                font-size: 25px;
            }
        }

        &.white {
            .content {
                h6 {
                    font-size: 1rem;
                }

                h4 {
                    font-size: 2.375rem;
                }

                // p {
                //     font-size: 1.375rem;
                // }

                &::before {
                    width: 80%;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .m-card.white .content::after {
        width: 95px;
        height: 95px;
    }
}

@media (max-width: 768px) {
    .m-card {
        display: block;
        padding-top: 25px;
        padding-bottom: 25px;
        min-height: 350px;
        &::before{
            height: 90px;
        }
        .content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            padding-top: 15px;
            padding-bottom: 15px;
            p {
                font-size: 1.25rem;
                margin-bottom: 20px;
            }
        }

        &.white {
            .content {
                height: auto;
                top: 0;
                position: relative;
                left: 0;
                transform: translate(0, 0);
                -moz-transform: translate(0, 0);
                -webkit-transform: translate(0, 0);

                &::after {
                    width: 90px;
                    height: 90px;
                    top: 0;
                }
                h6 {
                    img {
                        height: 100px;
                    }
                }
                p{
                    margin-bottom: 15px;
                }
            }

            .cat-cards .img-wrapper {
                height: 275px;
            }
        }
    }
    #blog-page {
        .m-card .content {
            &::before {
                display: none;
            }
            h3 {
                margin-top: 0;
                margin-bottom: 44px;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    width: 70vw;
                    height: 1px;
                    top: 44px;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    -moz-transform: translate(-50%, 0%);
                    -webkit-transform: translate(-50%, 0%);
                    border-top: 2px dashed #fff;
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .m-card {
        min-height: 340px;

        &.white .content {
            h6 {
                margin-bottom: 39px;
                img {
                    left: -70px;
                    top: 0;
                    height: 75px;
                }
            }

            h4 {
                font-size: 1.25rem;
                margin-bottom: 10px;
                max-width: 247px;
            }

            p {
                // font-size: 0.9375rem;
                margin: 0 auto 15px auto;
                max-width: 247px;
            }

            &::before {
                top: 54px;
            }
        }

        &::before {
            height: 53px;
        }
    }

    .cat-cards {
        .img-wrapper {
            height: 275px;
        }
    }
}

@media (max-width: 320px) {
    .m-card {
        &.white .content {
            h6 {
                img {
                    left: -55px;
                    height: 70px;
                }
            }
        }
    }
}
