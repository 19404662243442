#ricette-page,
#ricette-single-page {
    .mob-filters {
        display: none;
    }
    .ricette-header {
        min-height: 485px;
        padding-top: 100px;
        .page-title {
            .crumb-title {
                font-size: 45px;
                margin-top: 22px;
                margin-bottom: 86px;
            }
        }
        .filter-bar {
            form {
                .input-grp {
                    margin-right: 18px;
                    img {
                        position: absolute;
                        @include ver-center();
                        left: 19px;
                        width: 15px;
                        height: 15px;
                    }
                    input {
                        @include borderRadius(30px);
                        border: 2px solid $red;
                        width: 555px;
                        padding: 10px 50px;
                        font-size: 25px;
                        font-weight: $light;
                        font-family: $oswald;
                        height: 55px;
                        line-height: 1.48;
                        &::placeholder {
                            opacity: 0.8;
                        }
                    }
                }
                button {
                    margin-right: 80px;
                    width: 189px;
                    height: 55px;
                    @include borderRadius(30px);
                    font-family: $oswald;
                    font-size: 20px;
                    font-weight: $bold;
                    border: 2px solid $dBlue;
                    &:hover {
                        background: #fff;
                        color: $dBlue;
                    }
                }
            }
        }
    }
}
@media (max-width: 1645px) {
    #ricette-page .ricette-header .filter-bar form {
        .input-grp input {
            width: 500px;
        }
        button {
            margin-right: auto;
        }
    }
}
@media (max-width: 1508px) {
    #ricette-page .ricette-header .filter-bar form .input-grp input {
        width: 460px;
    }
}
@media (max-width: 1480px) {
    #ricette-page .ricette-header .filter-bar form {
        .input-grp {
            max-width: 320px;
            input {
                width: 100%;
            }
        }
    }
}
@media (max-width: 1440px) {
    #ricette-page .ricette-header .filter-bar form {
        .input-grp {
            input {
                width: 100%;
            }
        }
        button {
            margin-right: 0.5rem;
        }
    }
}
@media (max-width: 1210px) {
    #ricette-page .ricette-header .filter-bar form {
        button {
            margin-right: 0;
            width: auto;
            padding: 0 35px;
        }
        .input-grp {
            max-width: 320px;
        }
    }
}

@media (max-width: 1210px) {
    .center-1200 {
        .category-6 {
            p {
                font-size: 18px;
            }
        }
    }
}
@media (max-width: 1024px) {
    #ricette-page .ricette-header .filter-bar form button {
        width: 110px;
        padding: 0;
    }
}
@media (max-width: 960px) {
    #ricette-page .ricette-header .filter-bar form {
        .input-grp {
            max-width: 240px;
            input {
                padding-right: 0;
            }
        }
    }
}
@media (max-width: 768px) {
    #ricette-page,
    #ricette-single-page {
        .ricette-header {
            .filter-bar form {
                .input-grp {
                    margin-right: 0;
                }
                .input-group {
                    width: 100%;
                    display: block;
                    margin-bottom: 38px;
                    .input-grp {
                        max-width: 100%;
                        input {
                            width: 100%;
                        }
                    }
                }
                button {
                    display: none;
                }
            }
            .page-title {
                h2,.crumb-title {
                    font-size: 30px;
                    margin-top: 0;
                    margin-bottom: 20px;
                }
            }
            a.back-btn {
                top: -70%;
                width: 34px;
                height: 28px;
                padding: 0;
                &::before {
                    width: 34px;
                    height: 28px;
                }
                p {
                    display: none;
                }
            }
        }
    }
    // Mobile Filters
    #ricette-page {
        .filter-results-container {
            background-size: auto;
            .mob-filters {
                display: flex;
            }
            .filters-wrapper-mob {
                display: none;
                background: #fff;
                width: 90%;
                max-width: 335px;
                margin: 0 auto;
                padding: 20px 0.5em;
                h3 {
                    font-size: 20px;
                }
                .recepies-check {
                    width: 100%;
                    &::after {
                        width: 90%;
                    }
                    .checkbox-fields {
                        flex-wrap: wrap;
                        justify-content: center !important;
                        align-items: center !important;
                        align-content: center !important;
                        .checkbox {
                            width: 33.333%;
                            margin-right: 0;
                            text-align: center;
                            .img-wrapper {
                                max-width: 58px;
                            }
                            .cat-title {
                                font-size: 15px;
                                br {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .sidebar-filter {
                    .img-wrapper {
                        width: 37px;
                        height: 37px;
                        margin-right: 6px;
                    }
                    h4 {
                        max-width: 100%;
                        line-height: 37px;
                        font-size: 20px;
                    }
                    &::after {
                        width: 90%;
                    }
                    &.difficulty-filter {
                        margin-bottom: 15px;
                        &::after {
                            display: none;
                        }
                    }
                }
                .sidebar-radio {
                    width: fit-content;
                    margin: 0 auto;
                    flex-wrap: wrap;
                    .radio {
                        margin: 0 0 25px 0;
                        .checkmark {
                            padding: 8px;
                            min-width: 90px;
                            .cat-title {
                                font-size: 15px;
                                position: relative;
                                @include ver-center();
                            }
                        }
                    }
                }
            }
        }
        .filter-bar {
            .mob-slider {
                display: block !important;
                .radio {
                    margin-right: 0 !important;
                    text-align: center;
                }
                .checkmark {
                    .img-wrapper {
                        max-width: 65px;
                    }
                    .cat-title {
                        font-size: 15px;
                    }
                }
                .m-slider {
                    margin-right: calc(var(--bs-gutter-x) / -2);
                    margin-left: calc(var(--bs-gutter-x) / -2);
                }
            }
        }
    }
}
@media (max-width: 425px) {
    #ricette-page
        .filter-results-container
        .filters-wrapper-mob
        .sidebar-radio
        .radio
        .checkmark {
        min-width: 80px;
    }
}
@media (max-width: 320px) {
    #ricette-page
        .filter-results-container
        .filters-wrapper-mob
        .sidebar-radio
        .radio
        .checkmark {
        min-width: 70px;
    }
}
