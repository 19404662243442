.section-title {
    font-size: 25px;
    font-family: $ptSerif;
    font-weight: $bold;
    font-style: italic;
}
.section-sub-title {
    font-size: 25px;
    font-family: $ptSerif;
}
.fs-italic {
    font-style: italic;
}
.fw-bold {
    font-weight: $bold;
}
.fw-regular {
    font-weight: $regular;
}
.color-red {
    color: $red;
}
.color-white {
    color: #fff;
}
.color-dblue {
    color: $dBlue;
}
.color-blue {
    color: #004f8a;
}
.bg-dblue {
    background: $dBlue;
}
.bg-red {
    background: $red;
}
.w-fit-content {
    width: fit-content;
}
.ff-oswald {
    font-family: $oswald !important;
}
.ff-ptSerif {
    font-family: $ptSerif !important;
}
.size-sub,
.size-para {
    font-size: 20px;
}
.size-title {
    font-size: 35px;
}

.fs-16 {
    font-size: 16px;
}
.fs-25{
    font-size: 25px !important;
}
.fs-20{
    font-size: 20px !important;
}
.fs-22{
    font-size: 22px !important;
}
.fs-18{
    font-size: 18px !important;
}
.fs-15{
    font-size: 15px !important;
}
.pl-30 {
    padding-left: 30px;
}
.ml-30 {
    margin-left: 30px;
}
