#ricette-single-page {
    .ricette-header {
        .recepie-meta {
            margin-bottom: 100px;
            h3 {
                font-size: 45px;
                margin-bottom: 18px;
            }
            h4 {
                font-size: 20px;
            }
        }
    }
    .video-tutorial {
        margin-bottom: 65px;
       
    }
    .short-description {
        min-height: 745px;
        padding: 48px 53px;
        margin-bottom: 54px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        .content {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            padding-top: 40px;
            font-family: $oswald;
            .main-attr {
                width: fit-content;
                margin: 0 auto 60px auto;
                li {
                    position: relative;
                    padding-left: 31px;
                    padding-right: 5px;
                    font-size: 20px;
                    font-family: $oswald;
                    font-weight: $light;
                    color: #4e4e4e;
                    &::before {
                        content: "";
                        position: absolute;
                        left: -4px;
                        @include ver-center();
                        width: 24px;
                        height: 24px;
                    }
                    &.time {
                        &::before {
                            background: url("../../img/icons/time-icon.svg");
                            @include bg-props(contain, center, no-repeat);
                        }
                    }
                    &.difficulty {
                        &::before {
                            background: url("../../img/icons/difficulty-icon.svg");
                            @include bg-props(contain, center, no-repeat);
                        }
                    }
                    &.forPeople {
                        &::before {
                            background: url("../../img/icons/users-icon.svg");
                            @include bg-props(contain, center, no-repeat);
                        }
                    }
                }
            }
            // p {
            // font-size: inherit;
            // color: #4e4e4e;
            // max-width: 31ch;
            // margin: 0 auto 1rem auto;
            // font-family: $ptSerif;
            // span {
            //     font: inherit !important;
            //     font-weight: inherit !important;
            // }
            // strong,
            // b {
            //     font-weight: $bold;
            // }
            // em {
            //     font-size: inherit !important;
            // }
            // }
            h4 {
                margin-bottom: 40px;
                font-size: 25px;
                line-height: 1.5;
                text-transform: uppercase;
            }
            ul {
                li {
                    width: fit-content;
                    margin: 0 auto;
                    font-weight: $light;
                    font-size: 25px;
                    margin-bottom: 25px;
                    strong,
                    b {
                        font-weight: $bold;
                    }
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .img-wrapper {
            width: 100%;
            height: 100%;
            position: absolute;
            @include abs-center();

            img {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }
    }
    .long-description {
        margin-bottom: 64px;
        .long-description-content {
            .instructions {
                h3 {
                    font-size: 30px;
                }
                p {
                    font-size: 30px;
                    font-family: $ptSerif;
                    font-weight: $regular;
                    margin-bottom: 60px;
                    color: #4e4e4e;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    strong {
                        font-weight: $bold;
                    }
                }
            }
            .tips {
                padding: 45px 50px;
                h3 {
                    margin-bottom: 12px;
                }
                h3,
                p {
                    font-size: 25px;
                    line-height: 1.88;
                    span {
                        color: #fff !important;
                    }
                }
            }
        }
    }
}
@media (max-width: 768px) {
    #ricette-single-page {
        .ricette-header {
            min-height: auto;
            a.back-btn {
                top: -20%;
            }
            .recepie-meta {
                margin-bottom: 30px;
                h3 {
                    font-size: 35px;
                    max-width: 15ch;
                    margin: 0 auto 18px auto;
                }
                h4 {
                    font-size: 20px;
                }
            }
        }
        .video-tutorial {
            width: 100%;
            padding: 0.5rem;
            .col-12 {
                padding: 0;
            }
        }
        .short-description {
            padding: 30px 8px;
            margin-bottom: 30px;
            min-height: auto;
            .img-wrapper {
                position: relative;
                top: 0;
                left: 0;
                transform: translate(0, 0);
                -moz-transform: translate(0, 0);
                -webkit-transform: translate(0, 0);
                margin-top: 50px;
            }
            .content {
                .main-attr {
                    li {
                        margin-right: 29px;
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }
                p {
                    font-size: 20px;
                }
                h4 {
                    font-size: 20px;
                }
                .ingrident-list {
                    margin-bottom: 49px;
                    li {
                        font-size: 20px;
                    }
                }
            }
        }
        .long-description {
            .long-description-content {
                .instructions {
                    h3 {
                        font-size: 20px;
                    }
                    p {
                        font-size: 15px;
                        margin-bottom: 20px;
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
                .tips {
                    padding: 24px 30px;
                    h3,
                    p {
                        font-size: 15px;
                        line-height: 1.26666667;
                        span {
                            color: #fff !important;
                        }
                    }
                }
            }
        }
        .related-recepies .related-wrapper .recepie-card-wrapper {
            margin-bottom: 50px;
        }
    }
}
@media (max-width: 375px) {
    #ricette-single-page .ricette-header a.back-btn {
        left: 0;
    }
}
