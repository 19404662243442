.radio-fields,
.checkbox-fields {
    .radio,
    .checkbox {
        margin-right: 98px;
        input {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            display: none;
        }
        &:last-of-type {
            margin-right: 0;
        }
        .checkmark {
            display: inline-block;
            opacity: 0.3;
            transition: 0.3s;
            &.active {
                opacity: 1;
            }
            .img-wrapper {
                height: 88px;
                max-width: 150px;
                margin-bottom: 0.5rem;
                margin-left: auto;
                margin-right: auto;
                img {
                    width: 100%;
                    height: 100%;
                    margin: 0 auto;
                    object-fit: contain;
                }
            }
            .cat-title {
                font-size: 20px;
                font-family: $oswald;
                text-align: center;
            }
        }
        input {
            // &:checked ~ .checkmark {
            //     opacity: 1;
            // }
            &:hover ~ .checkmark {
                // opacity: 0.8;
                cursor: pointer;
            }
        }
        &.active {
            .checkmark {
                opacity: 1 !important;
            }
        }
    }
}

.sidebar-filter {
    position: relative;
    margin-bottom: 50px;
    .img-wrapper {
        width: 55px;
        height: 55px;
        margin: 0 auto 11px auto;
    }
    h4 {
        font-size: 25px;
        max-width: 10ch;
        margin: 0 auto 30px auto;
    }
    &::after {
        position: absolute;
        content: "";
        height: 3px;
        width: 50%;
        bottom: -25px;
        @include hor-center();
        border-bottom: 2px dashed $dBlue;
    }
}
.sidebar-radio {
    .radio {
        width: fit-content;
        margin: 0 auto 18px auto;
        input {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            display: none;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
        &.active {
            .checkmark {
                background: $red;
                p.cat-title {
                    color: #fff;
                }
            }
        }
        .checkmark {
            display: inline-block;
            transition: 0.3s;
            height: 53px;
            @include borderRadius(25px);
            padding: 12px 23px;
            border: 2px solid transparent;
            .cat-title {
                font-size: 20px;
                font-family: $oswald;
                text-align: center;
            }
        }
        input {
            &:checked ~ .checkmark {
                background: $red;
                p.cat-title {
                    color: #fff;
                }
            }
            & ~ .checkmark {
                border: 2px solid $red;
                cursor: pointer;
            }
            // &:hover ~ .checkmark {
            //     background: $red;
            //     p.cat-title {
            //         color: #fff;
            //     }
            // }
        }
    }
}
@media (max-width: 1366px) {
    .radio-fields,
    .checkbox-fields {
        .radio,
        .checkbox {
            margin-right: 30px;
            &:last-of-type {
                margin-right: 0;
            }
            .checkmark {
                .img-wrapper {
                    max-width: 90px;
                }
            }
        }
    }
}
@media (max-width: 1200px) {
    .radio-fields .radio,
    .radio-fields .checkbox,
    .checkbox-fields .radio,
    .checkbox-fields .checkbox {
        margin-right: 2px;
        .cat-title {
            font-size: 18px !important;
            max-width: 70%;
            margin: 0 auto;
        }
    }
}
