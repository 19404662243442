#product-single-page,
#product-page,
#ricette-page,
#ricette-single-page,
#blog-page,
#blog-single-page,
#about-page {
    .fixed-locator {
        position: absolute;
        top: 25vh;
        right: 5%;
    }
}
.fixed-locator {
    width: 169px;
    height: 169px;
    @include borderRadius(50%);
    box-shadow: 20px 23px 26px rgba(0, 0, 0, 0.16);
    position: fixed;
    right: 35px;
    top: 20vh;
    text-align: center;
    background: #fff;
    transition: 0.6s;
    z-index: 9999;
    &:hover {
        box-shadow: 2px 2px 26px rgb(0 0 0 / 16%);
    }
    &:focus {
        box-shadow: inset 0px 0px 15px 1px rgb(0 0 0 / 16%);
        outline: none;
    }
    .content-wrapper {
        position: absolute;
        @include abs-center();
        .img-wrapper {
            width: 43px;
            height: 60px;
            margin: 0 auto 3px auto;
        }
        p {
            font-size: 18px;
            color: $dBlue;
            font-family: $ptSerif;
            font-weight: $bold;
            font-style: italic;
            max-width: 8ch;
            margin: 0 auto;
        }
        .logo-wrapper {
            width: 78px;
            height: 25px;
            margin: 6px auto 0 auto;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
.mobile-locator {
    display: none;
}
@media (max-width: 768px) {
    .fixed-locator {
        display: none;
        // top: 85vh;
        // width: 110px;
        // height: 110px;
        // .content-wrapper {
        //     .img-wrapper {
        //         width: 20px;
        //         height: auto;
        //     }
        //     p {
        //         font-family: 14px;
        //     }
        // }
    }
    .mobile-locator {
        // display: block;
        &.show {
            display: block;
        }
        padding: 15px;
        box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.16);
        .close-locator {
            position: absolute;
            right: 12px;
            top: 0;
            width: fit-content;
            padding: 0;
        }
        .locator {
            a {
                width: 32px;
                height: 32px;
                margin: 0 auto 14px auto;
                display: block;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            p {
                font-size: 15px;
                font-family: $oswald;
                color: #4e4e4e;
                padding: 8px 10px;
                border: 1px solid #707070;
                @include borderRadius(15px);
                text-align: center;
            }
        }
        h4 {
            text-transform: uppercase;
            font-size: 15px;
            font-family: $oswald;
            margin-bottom: 15px;
            padding-right: 28px;
            // text-align: center;
        }
        .nearest-store {
            position: relative;
            padding-left: 40px;
            margin-bottom: 15px;
            // @include ver-center();
            &::before {
                content: "";
                position: absolute;
                left: 0;
                @include ver-center();
                width: 36px;
                height: 48px;
                background: url("../../img/icons/mobile-map-pin.svg");
                @include bg-props(contain, center center, no-repeat);
            }
            h3 {
                font-size: 20px;
                font-family: $ptSerif;
            }
            p {
                font-family: $oswald;
                font-size: 15px;
            }
        }
    }
}
.mobile-locator-trigger {
    right: 15px;
    bottom: 0;
    width: fit-content;
    left: auto;
    display: none;
    a {
        display: block;
        width: 45px;
        height: auto;
        background: #01253e;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        border-top-right-radius: 25px;
        -moz-border-top-right-radius: 25px;
        -webkit-border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        -moz-border-top-left-radius: 25px;
        -webkit-border-top-left-radius: 25px;
        padding-top: 10px;
        img {
            width: 100%;
            height: auto;
        }
    }
}
@media (max-width: 1440px) {
    .fixed-locator {
        transform: scale(0.7);
    }
}
