.filter-results-container {
    background-color: #f4f4f4;
    margin-top: 42px;
    padding-bottom: 77px;
    background-image: url("../../img/decoration/horizontal-bg-curve.svg");
    background-attachment: fixed;
    @include bg-props(cover, center, repeat);
    .recepies-check {
        position: relative;
        margin-bottom: 72px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        &::after {
            content: "";
            position: absolute;
            bottom: -36px;
            @include hor-center();
            width: 100%;
            height: 3px;
            border-top: 2px dashed $dBlue;
        }
    }
}
@media (max-width: 768px) {
    .mobileFilters {
        background: #fff;
        padding: 9px 37px;
        width: 90%;
        max-width: 335px;
        margin: 22px auto;
        @include borderRadius(0);
        position: relative;
        h3 {
            color: $blue;
            font-size: 20px;
            font-weight: $bold;
            font-style: italic;
            text-align: center;
            border-right: 1px solid #707070;
        }
        &::before {
            content: "";
            position: absolute;
            left: 11px;
            @include ver-center();
            background: url("../../img/icons/filter-icon.svg");
            @include bg-props(contain, center, no-repeat);
            width: 24px;
            height: 24px;
        }
        &::after {
            content: "";
            position: absolute;
            right: 11px;
            @include ver-center();
            background: url("../../img/icons/poly-down.svg");
            @include bg-props(contain, center, no-repeat);
            width: 14px;
            height: 23px;
            transition: 0.3s;
        }
        &.opened {
            &::after {
                transform: translate(0%, -50%) rotate(180deg);
                -moz-transform: translate(0%, -50%) rotate(180deg);
                -webkit-transform: translate(0%, -50%) rotate(180deg);
            }
        }
    }
}
