.recepie-card-wrapper {
    position: relative;
    padding: 34px 14px;
    background: #fff;
    max-width: 400px;
    width: 100%;
    text-align: center;
    transition: 0.6s;
    &:hover {
        transform: scale(1.08);
        overflow: visible;
    }
    .add-to-fav {
        position: absolute;
        @include hor-center();
        top: -25px;
        width: 52px;
        height: 52px;
        @include borderRadius(50%);
        background: #fff;
        color: $red;
        i {
            position: absolute;
            @include abs-center();
            @include borderRadius(50%);
            border: 2px solid $red;
            padding: 10px;
            font-size: 1.2rem;
        }
    }
    .img-wrapper {
        width: 100%;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        height: 236px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
    h3 {
        color: $dBlue;
        font-family: $oswald;
        font-weight: $bold;
        font-size: 18px !important;
        margin-bottom: 14px;
    }
    p {
        color: $red;
        font-family: $ptSerif;
        font-weight: $bold;
        font-style: italic;
        font-size: 20px;
        line-height: 1.2;
        max-width: 20ch;
        margin: 0 auto 25px auto;
        min-height: 96px;
    }
    ul {
        li {
            position: relative;
            padding-left: 31px;
            padding-right: 5px;
            font-size: 20px;
            font-family: $oswald;
            font-weight: $light;
            color: #4e4e4e;
            &::before {
                content: "";
                position: absolute;
                left: -4px;
                @include ver-center();
                width: 24px;
                height: 24px;
            }
            &.time {
                &::before {
                    background: url("../../img/icons/time-icon.svg");
                    @include bg-props(contain, center, no-repeat);
                }
            }
            &.difficulty {
                &::before {
                    background: url("../../img/icons/difficulty-icon.svg");
                    @include bg-props(contain, center, no-repeat);
                }
            }
            &.forPeople {
                &::before {
                    background: url("../../img/icons/users-icon.svg");
                    @include bg-props(contain, center, no-repeat);
                }
            }
        }
    }
}
.recepie-slider-wrapper {
    .slick-arrow {
        position: absolute;
        @include ver-center();
        width: 80px;
        height: 80px;
        z-index: 9;
        &.slick-next {
            right: 0;
        }
        &.slick-prev {
            left: 0;
        }
    }
}
@media (max-width: 1440px) {
    .recepie-card-wrapper {
        width: 95%;
        margin: 0 auto 12px auto;
    }
}

@media (max-width: 1040px) {
    .recepie-card-wrapper {
        width: 90%;
        margin: 0 auto;
        .img-wrapper {
            margin: 0 auto;
            width: 90%;
        }
        p {
            font-size: 1rem;
        }
        ul {
            li {
                padding-left: 20px;
                font-size: 1rem;
                &::before {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .recepie-card-wrapper {
        padding: 34px 14px 34px 14px;
        .img-wrapper {
            margin: 0 auto 12px auto;
        }
        p,
        h3 {
            max-width: 90%;
            margin-right: auto;
            margin-left: auto;
        }
        ul {
            li {
                margin-right: 28px !important;
                &:last-of-type {
                    margin-right: 0 !important;
                }
            }
        }
    }
}
