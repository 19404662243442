.about-tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    align-content: center;
    border-bottom: none;
    li {
        margin-right: 10px;

        &:last-of-type {
            margin-right: 0;
        }

        a {
            display: block;
            text-align: center;
            padding: 25px;
            min-width: 269px;
            transition: 0.6s;
            border: 1px solid rgba(255, 255, 255, 0);

            border-top-left-radius: 50px;
            -moz-border-top-left-radius: 50px;
            -webkit-border-top-left-radius: 50px;

            border-top-right-radius: 50px;
            -moz-border-top-right-radius: 50px;
            -webkit-border-top-right-radius: 50px;
            .img-wrapper {
                width: 70px;
                height: 70px;
                margin-bottom: 1rem;
            }
            h4 {
                font-size: 25px;
                font-weight: $bold;
                font-style: italic;
                font-family: $ptSerif;
                color: $dBlue;
            }
            &.active {
                background: #f5f5f5;
            }
            &:hover {
                border-top: 1px solid rgba(0, 0, 0, 0.16);
                border-left: 1px solid rgba(0, 0, 0, 0.16);
                border-right: 1px solid rgba(0, 0, 0, 0.16);
            }
        }
    }
}
.story-tab-mobile {
    display: none;
}
.tab-content {
    padding: 58px 77px 5px 77px;
    h2 {
        width: fit-content;
        margin: 0 auto 40px auto;
        font-size: 55px;
        font-weight: $bold;
        font-family: $ptSerif;
        font-style: italic;
        position: relative;
        color: $red;
        &::before {
            content: "";
            position: absolute;
            background: url("../../img/icons/red-divider.svg");
            @include bg-props(contain, center, no-repeat);
            width: 154px;
            height: 9px;
            left: 0;
            top: 50%;
            transform: translate(-200px, -50%);
            -moz-transform: translate(-200px, -50%);
            -webkit-transform: translate(-200px, -50%);
        }
        &::after {
            content: "";
            position: absolute;
            background: url("../../img/icons/red-divider.svg");
            @include bg-props(contain, center, no-repeat);
            width: 154px;
            height: 9px;
            right: 0;
            top: 50%;
            transform: translate(200px, -50%) rotate(180deg);
            -moz-transform: translate(200px, -50%) rotate(180deg);
            -webkit-transform: translate(200px, -50%) rotate(180deg);
        }
    }

    .essence-tab {
        .right-img {
            .img-wrapper {
                margin-top: 25%;
            }
        }
        .text-content {
            p {
                color: $dBlue;
                font-size: 22px;
                font-style: italic;
                font-family: $ptSerif;
                margin-bottom: 57px;
                padding: 0 50px;
                font-weight: $regular;
                strong {
                    font-weight: $bold;
                }
                &:last-of-type {
                    margin-bottom: 15px;
                }
            }
        }
        .quote-row {
            .quote {
                background: #fff;
                padding: 30px;
                border: 6px dashed $blue;
                @include borderRadius(85px);
                blockquote {
                    text-align: center;
                    font-size: 22px;
                    font-weight: $bold;
                    font-style: italic;
                    font-family: $ptSerif;
                    color: $dBlue;
                    cite {
                        font-size: 22px;
                        display: block;
                        margin-bottom: 25px;
                        line-height: 1.28571429;
                    }
                }
            }
            .img-wrapper {
                margin: 20px auto 0 auto;
                width: 254px;
                height: 257px;
                @include rotate(-15);
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        #promo-cta {
            margin-top: -100px;
        }
    }
    .technique-tab {
        .left-img {
            .img-wrapper {
                margin-top: 110%;
            }
        }
        .right-img {
            .img-wrapper {
                margin-top: 50%;
            }
        }
        .text-content {
            p {
                color: $dBlue;
                font-size: 22px;
                font-style: italic;
                font-family: $ptSerif;
                margin-bottom: 57px;
                // padding: 0 50px;
                font-weight: $regular;
                strong {
                    font-weight: $bold;
                }
                &:last-of-type {
                    margin-bottom: 90px;
                }
            }
        }
        .after-img {
            .img-wrapper {
                max-width: 694px;
                max-height: 477px;
                margin-top: 110px;
                margin-bottom: 70px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .story-tab {
        .story-row {
            position: relative;
            padding-bottom: 220px;
            &:not(:first-of-type) {
                margin-top: -130px;
            }
            .story-after {
                width: 250px;
                height: 240px;
                position: absolute;
                @include hor-center();
                bottom: 0;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .story-img {
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                .img-fluid {
                    width: 100%;
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .text-content {
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                p {
                    text-align: center;
                    color: $dBlue;
                    font-family: $ptSerif;
                    font-size: 22px;
                    line-height: 1.31428571;
                    font-style: italic;
                    max-width: 40ch;
                    margin: 0 auto;
                }
            }
        }
    }
}
@media (max-width: 1440px) {
    .tab-content {
        padding: 58px 77px 5px 77px;
        .story-tab .story-row {
            &:not(:first-of-type) {
                margin-top: -100px;
            }

            .story-after {
                width: 220px;
                height: 220px;
                bottom: 20px;
            }
        }
        .essence-tab {
            .text-content {
                p {
                    padding: 0;
                    &:last-of-type {
                        margin-bottom: 45px;
                    }
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    .about-tabs li a {
        min-width: 200px;
    }
    .tab-content {
        padding: 58px;
        .story-tab .story-row {
            &:last-of-type {
                padding-bottom: 60px;
            }
            .text-content p {
                font-size: 24px;
            }
            .story-after {
                width: 170px;
                height: 150px;
                bottom: 50px;
                left: calc(50% + -20px);
            }
        }
        .essence-tab {
            .text-content {
                p {
                    font-size: 25px;
                }
            }
            .quote-row {
                .quote {
                    padding: 15px;
                    blockquote {
                        font-size: 22px;
                        cite {
                            font-size: 25px;
                        }
                    }
                }
                .img-wrapper {
                    margin-bottom: 100px;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .about-tabs {
        flex-wrap: nowrap;
        padding: 0 8px;
        li {
            margin-right: 0;
            &:last-of-type {
                margin-right: 0;
            }
            a {
                padding: 15px;
                min-width: 50px;
                width: 139px;
                .img-wrapper {
                    width: 59px;
                    height: 46px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                h4 {
                    font-size: 15px;
                }
            }
        }
    }
    .about-content {
        padding-left: 0;
        padding-right: 0;
        .row {
            &:first-of-type {
                margin-left: 0;
                margin-right: 0;
                .col-12 {
                    &:first-of-type {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
    .story-tab {
        display: none;
    }
    .story-tab-mobile {
        display: block;
        // .slick-list {
        //     margin-right: -20vw;
        // }
        .single-slide {
            display: block;
            // margin: 0 20vw 0 0;

            .img-wrapper {
                margin: 0 auto 42px auto;
                text-align: center;
                width: 215px;
                height: auto;
                svg {
                    width: 100%;
                    height: 100%;
                }
            }
            p {
                max-width: 265px;
                margin: 0 auto;
                font-size: 15px;
                text-align: center;
                font-style: italic;
                line-height: 1.25;
                font-family: $ptSerif;
                color: $dBlue;
            }
        }
    }
    .tab-content {
        padding: 58px 0;
        h2 {
            font-size: 35px;
            &::before,
            &::after {
                display: none;
            }
        }
        .essence-tab,
        .technique-tab {
            .right-img,
            .left-img {
                .img-wrapper {
                    margin-top: 0;
                }
            }
        }
        .essence-tab,
        .technique-tab {
            .text-content {
                p {
                    font-size: 15px;
                    margin-bottom: 25px;
                    padding: 0;
                    &:last-of-type {
                        margin-bottom: 55px;
                    }
                }

                .img-wrapper {
                    max-width: 177px;
                    margin: 0 auto;
                }
            }

            .quote-row {
                padding-left: 0;
                padding-right: 0;
                margin-left: -1rem;
                margin-right: -1rem;
                .img-wrapper {
                    display: none;
                }
                .col-lg-7 {
                    padding-left: 0;
                    padding-right: 0;
                    .quote {
                        @include borderRadius(0);
                        border-left: none;
                        border-right: none;
                    }
                }
            }
            #promo-cta {
                margin-top: 35px;
            }
            .after-img {
                .img-wrapper {
                    margin-top: 44px;
                }
            }
        }
    }
}
@media (max-width: 320px) {
    .about-tabs {
        li {
            a {
                width: 102px;
            }
        }
    }
    .story-tab-mobile {
        .single-slide {
            margin: 0 10px 0 0;
        }
        .slick-list {
            padding: 0;
            margin: 0 -10px 0 0;
        }
    }
}
