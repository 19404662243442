#loginModal,
#avatarModal {
    .modal-content {
        @include borderRadius(0);
        box-shadow: 0 15px 25px rgba(0, 0, 0, 0.22);
        border: none;
        padding-bottom: 56px;
        .modal-header {
            border-bottom: none;
            position: relative;
            .btn-close {
                position: absolute;
                right: 10px;
                border: none;
                background: none;
                z-index: 999;
            }
        }
        .img-wrapper {
            margin: 0 auto 17px auto;
            width: 98px;
        }
        h5 {
            font-size: 25px;
            margin-bottom: 30px;
        }
        h4 {
            font-size: 25px;
        }
        .register-btn {
            min-width: 269px;
        }
        form {
            border-bottom: 2px dashed $dBlue;
            padding-bottom: 30px;
            margin-bottom: 30px;
            margin-left: auto;
            margin-right: auto;
            max-width: 482px;
            input {
                &.form-control {
                    @include borderRadius(30px);
                    border: 2px solid $red;
                    margin-bottom: 25px;
                    height: 55px;
                    font-size: 25px;
                    font-weight: $light;
                    color: #4e4e4e;
                    padding: 8px 30px;
                    font-family: $oswald;
                }
                &.form-check-input {
                    width: 19px;
                    height: 19px;
                    @include borderRadius(0);
                    &:checked {
                        background-color: $red;
                        border-color: $red;
                    }
                }
            }
            .form-check {
                position: relative;
                margin-left: 30px;

                label {
                    font-family: $ptSerif;
                    font-weight: $bold;
                    font-style: italic;
                    font-size: 15px;
                    color: #4e4e4e;
                    position: absolute;
                    @include ver-center();
                }
            }
            .forgot-pass {
                font-family: $ptSerif;
                font-weight: $bold;
                font-style: italic;
                font-size: 15px;
                color: #4e4e4e;
                text-align: right;
                display: block;
                margin-right: 30px;
                position: relative;
                @include ver-center();
            }
            a[type="submit"] {
                margin-top: 25px;
                min-width: 269px;
                &:hover {
                    border-color: $dBlue;
                }
            }
        }
    }
}
#avatarModal {
    .modal-content {
        h5 {
            margin-bottom: unset;
        }
        form {
            border-bottom: unset;
            margin: unset;
            max-width: unset;
        }
        .img-wrapper {
            width: 100%;
            height: 100%;
            max-width: 100%;
        }
    }
}
@media (max-width: 768px) {
    #loginModal,
    #avatarModal {
        .modal-content {
            padding-bottom: 45px;
            a[type="submit"],
            a.register-btn {
                height: 55px;
                padding-top: 0;
                padding-bottom: 0;
                font-size: 20px;
                line-height: 55px;
            }
            form {
                .form-check {
                    width: fit-content;
                    margin-left: -30px;
                    position: relative;
                    @include hor-center();
                    label {
                        width: auto;
                        margin-left: 9px;
                    }
                    input {
                        float: none;
                    }
                }
                .forgot-pass {
                    margin-right: 0;
                    text-align: center;
                }
            }
        }
    }
}
