.breadcrumbs-wrapper {
    margin-bottom: 35px;
    .breadcrumbs {
        margin-bottom: 22px;
        margin-top: 74px;
        p {
            font-size: 18px;
            text-align: center;
        }
    }
    .page-title {
        h2 {
            font-size: 35px !important;
        }
    }
}
.breadcrumbs {
    .breadcrumb {
        background: transparent;
    }

    margin-bottom: 22px;
    margin-top: 74px;
    li {
        font-size: 18px;
    }
}
@media (max-width:1440px) {
    .breadcrumbs-wrapper {
        .page-title{
            h2{
                font-size: 25px !important;
            }
        }
    }
}
@media (max-width: 768px) {
    .breadcrumbs {
        margin-top: 37px;
        li {
            font-size: 15px;
        }
    }
}
