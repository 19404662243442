// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@import "~bootstrap/scss/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~leaflet/dist/leaflet.css";
@import "~mapbox-gl/dist/mapbox-gl.css";

@import "variables";
@import "abstracts/helpers/typography";
@import "abstracts/helpers/opacity";
@import "abstracts/helpers/helpers";
@import "abstracts/helpers/animations";

// Bootstrap

@import "base/reset";
@import "layout/header";
@import "layout/footer";
@import "layout/custom-layouts";
@import "components/buttons";
@import "components/notification";
@import "components/fixed-locator";
@import "components/categories-widget";
@import "components/cat-cards";
@import "components/cta-recepies";
@import "components/recepie-card";
@import "components/promo-cta";
@import "components/newsletter";
@import "components/sliders";
@import "components/scroll";
@import "components/breadcrums";
@import "components/radio-fields";
@import "components/filter-results-container";
@import "components/ad-baner";
@import "components/related-recepies";
@import "components/second-navigation";
@import "components/login-modal";
@import "components/select2";
@import "components/related-products";
@import "pages/ricette";
@import "pages/ricette-single";
@import "pages/products";
@import "pages/register";
@import "pages/about";
@import "pages/products-single";
@import "pages/404";
@import "pages/blog";
@import "pages/profile";
@import "pages/stores";
@import "pages/contact";
@import "pages/certification";
@import "pages/confirmation";
@import "pages/video-player-page";
@import '~flag-icon-css/sass/flag-icon';

html {
    scroll-behavior: smooth;
}

.divider-dashed {
    background: transparent;
    border: 2px dashed $dBlue;
    opacity: 1;
}

.modal {
    z-index: 999999;
}
.modal-backdrop {
    &.show {
        z-index: 99999;
    }
}
.swal2-container {
    z-index: 9999999 !important;
}

.divider-dashed-vertical {
    border-left: 2px dashed #01253e;
}
h2 {
    font-size: 25px !important;
}
h4 {
    font-size: 18px !important;
}
h3{
    font-size: 25px!important;
}
.cookies-gdpr{
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    z-index: 99999;
    color: #004f8a;
    text-align: center;
    -webkit-box-shadow: -1px 0px 8px 0px rgb(0 0 0 / 20%);
    box-shadow: -1px 0px 8px 0px rgb(0 0 0 / 20%);
    p{
        color: rgb(78, 78, 78);
        line-height: 1.5;
        font-weight: 300;
    }
}
.min-height-unset{
    min-height: unset;
}