#page-width-wrapper {
    padding: 0 47px;
}
.bg-gray-stripe {
    background-image: url("../../img/decoration/horizontal-bg-curve.svg");
    @include bg-props(cover, center, repeat);
    background-color: #f4f4f4;
    background-attachment: fixed;
}
@media (max-width: 768px) {
    #page-width-wrapper {
        padding: 0;
    }
}
// Boxed View
.w-1440 {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}
