#profile {
    .user-data {
        .img-wrapper {
            // gap: 50px;
            .wrap {
                width: 180px;
                height: 180px;
                margin: 0 auto 50px auto;
                img{
                    object-fit: cover;
                    object-position: center;
                }
            }
            a {
                font-size: 15px;
                line-height: 37px;
                color: #4e4e4e;
                text-decoration: underline;
                position: relative;

                &::before {
                    @include hor-center();
                    content: "";
                    position: absolute;
                    background-image: url("../../img/icons/photo-icon.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 30px;
                    height: 30px;
                    top: -30px;
                }
            }
        }
    }
    .recepie-card-wrapper {
        border: 1px solid #707070;
        .add-to-fav {
            border-top: 1px solid #707070;
        }
    }
    span {
        &.add-to-fav {
            position: absolute;
            @include hor-center();
            top: -10px;
            left: 15px;
            width: 52px;
            height: 52px;
            @include borderRadius(50%);
            background: #fff;
            color: $red;

            i {
                position: absolute;
                @include abs-center();
                @include borderRadius(50%);
                border: 2px solid $red;
                padding: 10px;
                font-size: 1.2rem;
            }
        }
    }

    .custom-avatar {
        background: #ededed;
        color: #000;
        border: unset;
        border-radius: unset;
        cursor: pointer;

        .avatar-picker {
            width: 100%;
            height: 100%;
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }

        p {
            color: #4e4e4e;
            text-decoration: underline;
            line-height: 37px;
            position: relative;

            &::before {
                @include hor-center();
                content: "";
                position: absolute;
                background-image: url("../../img/icons/photo-icon.svg");
                background-repeat: no-repeat;
                background-size: contain;
                width: 30px;
                height: 30px;
                top: -30px;
            }
        }
    }
}
@media (max-width: 1440px) {
    #profile .user-data .img-wrapper .wrap{
        width: 180px;
        height: 180px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 768px) {
    #profile {
        .user-data {
            .divider-dashed-vertical {
                border-left: unset;
                border-top: 2px dashed #01253e;
            }
        }
        .custom-avatar {
            p {
                line-height: 1.3;
                font-size: 1rem;
            }
        }
    }
}
