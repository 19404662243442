.register-section {
    margin-bottom: 90px;
    .step-track {
        margin-bottom: 36px;
        .md-stepper-horizontal {
            display: table;
            width: 100%;
            margin: 0 auto;
            background-color: #ffffff;
            .md-step {
                display: table-cell;
                position: relative;
                padding: 24px;
                width: 33.3333333333%;
                &:hover {
                    cursor: pointer;
                    .md-step-circle {
                        &::before {
                            content: "";
                            position: absolute;
                            @include abs-center();
                            width: 34px;
                            height: 34px;
                            @include borderRadius(50%);
                            background: $dBlue;
                            opacity: 0.5;
                        }
                    }
                }

                &:first-child {
                    &:active {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                    .md-step-bar-left {
                        display: none;
                    }
                }
                &:last-child {
                    &:active {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
                .md-step-circle {
                    z-index: 99;
                    width: 62px;
                    height: 62px;
                    margin: 0 auto;
                    background-color: #fff;
                    border: 1px solid #707070;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 30px;
                    font-size: 16px;
                    font-weight: 600;
                    color: #ffffff;
                    position: relative;
                }
                .md-step-title {
                    margin-top: 16px;
                    font-size: 20px;
                    font-weight: $bold;
                    font-style: italic;
                    font-family: $ptSerif;
                    color: $dBlue;
                    opacity: 0.5;
                }
                .md-step-optional {
                    text-align: center;
                    color: rgba(0, 0, 0, 0.26);
                    font-size: 12px;
                }
                .md-step-bar-left {
                    position: absolute;
                    top: calc(50% + -16px);
                    width: 100%;
                    height: 1px;
                    border-top: 1px solid #dddddd;
                    left: -50%;
                    right: 0%;
                    margin-right: 0;
                }
            }

            .md-step.done {
                .md-step-circle {
                    border: 2px solid $red;
                    &:before {
                        content: "";
                        position: absolute;
                        width: 34px;
                        height: 34px;
                        @include abs-center();
                        background: $dBlue;
                        @include borderRadius(50%);
                    }
                    * {
                        display: none;
                    }
                }

                .md-step-bar-left {
                    border-top: 2px solid $red;
                }
                .md-step-title {
                    opacity: 1;
                }
            }
            .md-step.editable {
                .md-step-circle {
                    * {
                        display: none;
                    }
                    -moz-transform: scaleX(-1);
                    -o-transform: scaleX(-1);
                    -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);
                    &:before {
                        font-family: "FontAwesome";
                        font-weight: 100;
                        content: "\f040";
                    }
                }
            }
        }
    }
    form {
        .form-title {
            font-size: 25px;
            max-width: 35ch;
            margin: 0 auto 29px auto;
        }
        &.red-inputs {
            .name-inputs {
                label {
                    display: block;
                }
                &.editable {
                    &.name-inputs {
                        margin-bottom: 0;
                    }
                    .name-input-wrapper {
                        width: 100%;
                        margin: 0 auto 0 0;
                        padding-right: calc(var(--bs-gutter-x) / 2);
                        padding-left: calc(var(--bs-gutter-x) / 2);
                    }
                }
            }
            label {
                font-weight: $light;
                font-family: $oswald;
                font-size: 1rem;
                margin-bottom: 12px;
                padding-left: 30px;
                color: #4e4e4e;
                &.color-red {
                    color: $red;
                    font-style: italic;
                    font-size: 25px;
                    font-weight: $bold;
                }
            }
            input {
                &[type="text"],
                &[type="password"],
                &[type="tel"],
                &[type="email"] {
                    border: 2px solid $red;
                    margin-bottom: 18px;
                    border-radius: 30px;
                    padding: 8px 30px;
                    height: 55px;
                    font-size: 25px;
                    font-weight: $light;
                    color: #4e4e4e;
                    font-family: $oswald;
                    &::placeholder {
                        color: #c6c6c6;
                    }
                }
                &[type="tel"] {
                    position: relative;
                    &::before {
                        position: absolute;
                        content: "";
                        width: 30px;
                        height: 30px;
                        background: green;
                    }
                }
            }
            .phone-input {
                .prefix {
                    position: absolute;
                    top: calc(50% + 8px);
                    transform: translate(0%, -50%);
                    -moz-transform: translate(0%, -50%);
                    -webkit-transform: translate(0%, -50%);
                    left: 40px;
                    font-size: 20px;
                    color: #4e4e4e;
                    font-weight: $light;
                    font-family: $oswald;
                    border-right: 1px solid #707070;
                    padding-right: 7.7px;
                }
                input {
                    padding-left: 70px;
                }
            }
            select,
            .select2 {
                border: 2px solid $red !important;
                margin-bottom: 18px;
                border-radius: 30px !important;
                padding: 8px 30px;
                height: 55px;
                font-size: 25px;
                font-weight: $light;
                color: #4e4e4e;
                font-family: $oswald;
                width: 100%;
                position: relative;
                -moz-appearance: window;
                -webkit-appearance: none;
                appearance: none;
                background: url("../../img/icons/select-icon.svg");
                background-size: 19px;
                background-position: 95% center;
                background-repeat: no-repeat;
            }
            .form-text {
                padding-left: 30px;
                font-size: 15px;
                font-weight: $light;
                line-height: 1.46666667;
                font-family: $oswald;
            }
            .form-check {
                padding-left: 30px;
                label {
                    padding-left: 14px;
                    font-size: 15px;
                    color: $dBlue;
                    font-style: italic;
                    font-weight: $bold;
                    font-family: $ptSerif;
                    line-height: 19px;
                }
                input {
                    margin: 0;
                    width: 19px;
                    height: 19px;
                    @include borderRadius(0);
                    &:checked {
                        border-color: $red;
                        background-color: $red;
                    }
                }
            }
            .privacy-info {
                p {
                    padding-left: 30px;
                    font-size: 20px;
                    font-weight: $light;
                    font-family: $oswald;
                    line-height: 1.5;
                    margin-bottom: 16px;
                    a {
                        color: $red;
                        text-decoration: underline;
                        font-weight: $bold;
                    }
                }
                .form-check {
                    label {
                        font-size: 15px;
                        color: #4e4e4e;
                        font-weight: $bold;
                        font-style: italic;
                    }
                }
            }
        }
    }
    .thank-you-page {
        .img-wrapper {
            margin: 0 auto 26px auto;
            max-width: 312px;
        }
        h4 {
            font-size: 41px;
            margin-bottom: 16px;
        }
        p {
            font-size: 25px;
            color: $dBlue;
            margin: 0 auto 20px auto;
            max-width: 40ch;
        }
    }
}
@media (max-width: 1024px) {
    .register-section form.red-inputs {
        .form-check {
            padding-left: 0;
        }
        label {
            min-height: 40px;
        }
    }
}
@media (max-width: 768px) {
    .register-section form.red-inputs .name-inputs.editable.name-inputs {
        display: block !important;
        .name-input-wrapper {
            width: 100%;
            margin: 0;
        }
    }
    .register-section form.red-inputs .name-inputs {
        display: block !important;
        text-align: center;
        .pl-30 {
            padding-left: 0;
            margin-bottom: 5px!important;
        }
    }
    .register-section {
        .step-track {
            overflow-x: scroll;
            .md-stepper-horizontal .md-step .md-step-bar-left {
                top: calc(50% + -25px);
                width: 200%;
            }
        }
        form.red-inputs {
            .form-text {
                font-size: 15px;
                margin-bottom: 18px;
                br {
                    display: none;
                }
            }
            .form-check {
                margin-right: calc(var(--bs-gutter-x) / -2);
                margin-left: calc(var(--bs-gutter-x) / -2);
                label {
                    max-width: calc(100% - 19px);
                }
            }
            .privacy-info {
                p,
                label {
                    padding-left: 0;
                }
                .form-check {
                    padding-left: 15px;
                }
            }
            .register-btn {
                margin-top: 28px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .thank-you-page {
            h4 {
                font-size: 25px;
            }
            p {
                font-size: 18px;
            }
        }
    }
}
