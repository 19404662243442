.push-content {
    padding-top: 196px; //push content based on header height
}

header {
    position: relative;
    width: 100%;
    height: 196px;
    z-index: 99999 !important;
    padding: 0 140px;
    // padding: 0 140px;
    // background: url("../../img/decoration/nav-bg.svg");
    // @include bg-props(cover,center,no-repeat);
    #menu-trigger {
        width: 42px;
        height: 1rem;
        position: absolute;
        right: 13.5%;
        bottom: 16%;
        z-index: 999;
    }
    .mobile-wrapper {
        display: none;
    }
    .svg-wrapper {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
        #menu-trigger-shape {
            position: fixed;
            right: 50px;
            top: 80px;
            z-index: 9;
            cursor: pointer;
            height: 90px;
            width: 320px;
        }
        img {
            width: 100%;
            height: auto;
            position: fixed;
            z-index: -1;
            top: -3px;
            left: 0;
        }
    }
    #menu-open {
        cursor: pointer;
        // padding: 5px;
    }
    &::before {
        position: absolute;
        // content: "";
        // background: url("../../img/decoration/nav-bg.svg");
        background-position: center center;
        background-size: 101%;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .social {
        ul {
            li {
                margin-right: 10px;
                &:last-of-type {
                    margin-right: 0;
                }
                a {
                    position: relative;
                    width: 30px;
                    height: 30px;
                    background: $dBlue;
                    i {
                        @include abs-center();
                        position: absolute;
                        color: $white;
                    }
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
    .logo-wrapper {
        max-width: 115px;
        height: auto;
        margin: 0 auto;
        text-align: center;
        a {
            display: block;
            width: 100%;
            height: 100%;
            img {
                max-height: 100%;
            }
        }
    }
    .toolbar {
        ul {
            li {
                &.language-switcher {
                    display: flex;
                    align-items: center;
                    margin-right: 15px;
                }

                position: relative;
                &::after {
                    position: absolute;
                    content: "";
                    width: 1px;
                    height: 100%;
                    max-height: 33px;
                    right: 0;
                    background: #707070;
                    @include ver-center();
                }
                &:last-of-type {
                    &::after {
                        display: none;
                    }
                }
                a {
                    cursor: pointer;
                    position: relative;
                    color: $dBlue;
                    font-family: $oswald;
                    font-weight: $regular;
                    font-size: 20px;
                    padding-left: 15px;
                    padding-right: 15px;
                    &:hover {
                        opacity: 0.8;
                    }
                    &.user {
                        padding-left: 30px !important;
                        &::before {
                            position: absolute;
                            content: "";
                            background: url("../../img/icons/user-icon.svg");
                            @include bg-props(contain, center, no-repeat);
                            width: 24px;
                            height: 24px;
                            left: -4px;
                            top: 50%;
                            @include ver-center();
                        }
                    }
                    &.locale {
                        padding-left: 30px !important;
                        &::before {
                            position: absolute;
                            content: "";
                            background: url("../../img/icons/world-ico.svg");
                            @include bg-props(contain, center, no-repeat);
                            width: 32px;
                            height: 32px;
                            left: -4px;
                            top: 50%;
                            @include ver-center();
                        }
                    }
                    &.login-btn {
                        color: #fff;
                        min-width: 119px;
                        font-size: 20px;
                        font-weight: $bold;
                        margin-right: 15px !important;
                        &:hover {
                            border-color: $dBlue;
                            color: $dBlue;
                        }
                    }
                }
                &.notification-d {
                    .notifications-wrapper {
                        display: none;
                        background: #fff;
                        padding: 30px;
                        width: 308px;
                        position: absolute;
                        right: -3px;
                        top: 50px;
                        box-shadow: 0 15px 25px rgb(0 0 0 / 22%);
                        text-align: center;
                        &::before {
                            content: "";
                            position: absolute;
                            right: 15px;
                            top: -15px;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 0 7.5px 15px 7.5px;
                            border-color: transparent transparent #ffffff
                                transparent;
                        }
                        h4 {
                            font-family: $oswald;
                            font-size: 20px;
                        }
                        h5 {
                            font-family: $ptSerif;
                            font-size: 25px;
                            margin-bottom: 7px;
                        }
                        h6 {
                            font-size: 15px;
                            max-width: 30ch;
                            margin: 0 auto 27px auto;
                        }
                        .img-wrapper {
                            width: 152px;
                            margin: 0 auto 42px auto;
                            position: relative;
                            &::after {
                                content: "";
                                position: absolute;
                                bottom: -26px;
                                width: 90%;
                                @include hor-center();
                                height: 3px;
                                border-top: 2px dashed $dBlue;
                            }
                        }
                        a {
                            font-size: 15px;
                            font-weight: $bold;
                            font-family: $oswald;
                        }
                    }
                }
            }
        }
    }
    #menu-popup {
        // box-shadow: 20px 23px 26px rgba(0, 0, 0, 0.16);
        left: 0;
        position: fixed;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        z-index: 9999999;
        padding-left: 0;
        // padding-top: 40px;
        background-color: #f4f4f4;
        display: none;
        opacity: 0;
        overflow-x: hidden;
        &::before {
            content: "";
            position: fixed;
            background: url("../../img/decoration/menu-repeat-bg.svg");
            @include bg-props(60px, center, repeat);
            width: 95%;
            height: 100%;
            top: 0;
            left: 0%;
            z-index: -1;
        }
        &::after {
            content: "";
            position: fixed;
            background: url("../../img/decoration/menu-curve.svg");
            @include bg-props(cover, center, repeat);
            width: 85%;
            height: 100%;
            top: 0;
            left: 25%;
            z-index: -2;
        }

        .latest-recepies {
            // margin-top: 100px;
            padding-left: 0;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;

            .card-wrapper {
                position: relative;
                // margin: 0 auto 35px auto;
                padding: 34px 14px;
                background: #fff;
                width: 335px;
                max-width: 100%;
                text-align: center;
                margin-bottom: 30px;
                &:last-of-type {
                    margin-bottom: 0;
                }
                .add-to-fav {
                    position: absolute;
                    @include hor-center();
                    top: -25px;
                    width: 52px;
                    height: 52px;
                    @include borderRadius(50%);
                    background: #fff;
                    color: $red;
                    i {
                        position: absolute;
                        @include abs-center();
                        @include borderRadius(50%);
                        border: 2px solid $red;
                        padding: 10px;
                        font-size: 1.2rem;
                    }
                }
                .img-wrapper {
                    width: 300px;
                    margin-bottom: 12px;
                    max-width: 100%;
                    height: 200px;
                    max-height: 200px;
                    margin-left: auto;
                    margin-right: auto;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
                h3 {
                    color: $dBlue;
                    font-family: $oswald;
                    font-weight: $bold;
                    font-size: 18px !important;
                    margin-bottom: 14px;
                }
                p {
                    color: $red;
                    font-family: $ptSerif;
                    font-weight: $bold;
                    font-style: italic;
                    font-size: 20px;
                    line-height: 1.2;
                    margin-bottom: 25px;
                }
                ul {
                    li {
                        position: relative;
                        padding-left: 31px;
                        padding-right: 5px;
                        font-size: 20px;
                        font-family: $oswald;
                        font-weight: $light;
                        color: #4e4e4e;
                        &::before {
                            content: "";
                            position: absolute;
                            left: -4px;
                            @include ver-center();
                            width: 24px;
                            height: 24px;
                        }
                        &.time {
                            &::before {
                                background: url("../../img/icons/time-icon.svg");
                                @include bg-props(contain, center, no-repeat);
                            }
                        }
                        &.difficulty {
                            &::before {
                                background: url("../../img/icons/difficulty-icon.svg");
                                @include bg-props(contain, center, no-repeat);
                            }
                        }
                        &.forPeople {
                            &::before {
                                background: url("../../img/icons/users-icon.svg");
                                @include bg-props(contain, center, no-repeat);
                            }
                        }
                    }
                }
            }
        }
        .latest-notification {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .card-wrapper {
                max-width: 360px;
                width: 100%;
                .img-wrapper {
                    height: 200px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: left bottom;
                    }
                }
                p {
                    margin-bottom: 50px;
                }
            }
            .menu-logo-wrapper {
                max-width: 300px;
                margin-top: 30px;
                // position: absolute;
                // bottom: 0;
                // width: 370px;
                // height: 294px;
                // img {
                //     width: 100%;
                //     height: 100%;
                // }
            }
        }
        #close-menu {
            background: url("../../img/decoration/close-menu-bg.svg");
            @include bg-props(100%, 0px -56px, no-repeat);
            width: 382px;
            height: 193px;
            cursor: pointer;
            position: relative;
            margin: -40px auto 0 auto;
            img {
                position: absolute;
                @include hor-center();
                bottom: 25px;
            }
        }
        .menu-items {
            ul {
                margin-right: 178px;
                display: flex;
                flex-direction: column;
                // gap: 70px;
                li {
                    margin-bottom: 45px;
                    // margin-top: 70px;
                    &:nth-last-of-type(2) {
                        margin-bottom: 0;
                    }
                    a {
                        color: $blue;
                        text-transform: uppercase;
                        // font-size: 3.125rem;
                        font-size: 35px;
                        font-family: $oswald;
                        font-weight: $bold;
                        &:hover {
                            color: $red;
                        }
                    }
                    &.brand {
                        display: none;
                    }
                }
            }
        }
    }
}
.loader-bg {
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    box-shadow: 20px 23px 26px rgba(0, 0, 0, 0.26);

    .img-wrapper {
        width: fit-content;
        position: absolute;
        @include abs-center();
        img {
            -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
            animation: heartbeat 1.5s ease-in-out infinite both;
        }
    }
}
@media (max-width: 1800px) {
    header .svg-wrapper #menu-trigger-shape {
        top: 70px;
    }
}
@media (max-width: 1650px) {
    header .svg-wrapper #menu-trigger-shape {
        top: 65px;
    }
}
@media (max-width: 1575px) {
    header .svg-wrapper #menu-trigger-shape {
        top: 56px;
    }
}

@media (max-width: 1440px) {
    .push-content {
        padding-top: 100px;
    }
    header {
        .desktop-menu {
            margin-top: 0.5rem;
        }
        .logo-wrapper {
            max-width: 200px;
            height: 60px;
        }
        #menu-popup {
            height: 100%;
            &::after {
                background-repeat: no-repeat;
                width: 100%;
                top: 0;
            }
            .menu-items {
                ul {
                    margin-right: 50px;
                    li {
                        a {
                            font-size: 35px;
                        }
                    }
                }
            }

            .notification-card {
                .img-wrapper {
                    max-width: 100%;
                    height: auto;
                }
                h3 {
                    font-size: 2rem;
                }
            }
            .latest-notification {
                justify-content: space-between;
                flex-direction: column;
                max-height: 100vh;
                padding-top: 30px;
                .menu-logo-wrapper {
                    margin-top: 0;
                    margin-bottom: 5px;
                }
                .card-wrapper {
                    .img-wrapper {
                        height: 168px;
                    }
                    p {
                        margin-bottom: 28px !important;
                    }
                    a.red-border {
                        padding: 0;
                        width: 157px;
                        height: 31px;
                        text-align: center;
                        line-height: 31px;
                    }
                }
            }
            .latest-recepies {
                padding-top: 2rem !important;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                .card-wrapper {
                    margin-bottom: 0 !important;
                    margin-top: 0 !important;
                    &:last-of-type {
                        display: none;
                    }
                }
            }
        }
        .social {
            ul {
                li {
                    a {
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }
        .toolbar {
            ul {
                li {
                    a {
                        &.user {
                            &::before {
                                width: 20px;
                                height: 20px;
                            }
                        }
                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
        .svg-wrapper #menu-trigger-shape {
            top: 50px;
        }
    }
}

@media (max-width: 1366px) {
    header {
        .svg-wrapper #menu-trigger-shape {
            top: 40px;
        }
    }
    header #menu-popup .latest-recepies .card-wrapper {
        width: 300px;
    }
}
@media (max-width: 1024px) {
    .push-content {
        padding-top: 55px;
    }
    header {
        height: auto;
        padding: 0 25px;
        .desktop-menu {
            margin-top: 0;
        }
        #menu-popup {
            &::before,
            &::after {
                height: 100%;
            }
            .latest-notification {
                a {
                    padding: 5px 15px;
                }
            }
            .latest-recepies .card-wrapper {
                transform: scale(0.8);
                margin-bottom: 0 !important;
            }
            &::after {
                left: 30%;
            }
        }
        .svg-wrapper #menu-trigger-shape {
            top: 30px;
        }
        .toolbar ul li:first-of-type {
            width: max-content;
        }
    }
}
@media (max-width: 768px) {
    .push-content {
        padding-top: 120px;
    }

    header {
        height: auto;
        padding: 0;
        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
        background: #fff;
        .mobile-wrapper {
            #mobile-menu-shape {
                position: absolute;
                @include hor-center();
                width: fit-content;
                overflow: visible;
                top: 50%;
                z-index: -1;
            }
        }
        .desktop-menu {
            margin-bottom: 3px !important;
        }
        .mobile-wrapper {
            display: block;
            position: absolute !important;
            width: 100%;
            .logo-wrapper {
                display: none;
            }
            .user-toolbar {
                display: none;
            }
        }
        .toolbar ul li a {
            font-size: 1rem;
        }
        .svg-wrapper {
            display: none;
        }
        .mobile-wrapper {
            height: 80px;
            background: #fff;
            // display: block;
            max-width: 100vw;

            .user-toolbar {
                right: 30px;
                @include ver-center();
                z-index: 9999;
                .mobile-notifications-wrapper {
                    background: #fff;
                    padding-top: 30px;
                    width: 215px;
                    position: fixed;
                    right: -5px;
                    top: 50px;
                    box-shadow: 0 15px 25px rgb(0 0 0 / 22%);
                    &::before {
                        content: "";
                        position: absolute;
                        right: 15px;
                        top: -15px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 7.5px 15px 7.5px;
                        border-color: transparent transparent #ffffff
                            transparent;
                    }
                    ul {
                        li {
                            color: $dBlue;
                            font-size: 20px;
                            font-family: $oswald;
                            font-weight: $regular;
                            border-bottom: 3px dashed rgba(1, 37, 62, 0.15);
                            padding: 15px 0;
                            margin: 0 auto;
                            width: 80%;
                            a {
                                color: $dBlue;
                                font-size: 20px;
                                font-family: $oswald;
                            }
                            &:last-of-type {
                                margin-bottom: 17px;
                                padding-bottom: 22px;
                            }
                            &:hover {
                                border-bottom: 3px dashed rgba(1, 37, 62, 1);
                            }
                            &.user,
                            &.bell {
                                width: 80%;
                                span {
                                    background: $red;
                                    padding: 2px 13px;
                                    font-size: 14px;
                                    font-weight: $regular;
                                    color: #fff;
                                    @include borderRadius(15px);
                                }
                            }

                            h4 {
                                font-size: 15px;
                                font-family: $ptSerif;
                                font-weight: $bold;
                                margin-bottom: 7px;
                            }
                            p {
                                font-size: 12px;
                                font-family: $ptSerif;
                                font-weight: $bold;
                                margin-bottom: 10px;
                            }
                            a {
                                padding: 5px 15px;
                                font-size: 12px;
                            }
                        }
                    }
                    .close-notification {
                        position: relative;
                        height: 46px;
                        a {
                            background: $red;
                            color: #fff;
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            line-height: 1.6;
                            font-weight: $regular;
                            font-size: 20px;
                            font-family: $oswald;
                            position: absolute;
                            &:hover {
                                background: $dBlue;
                            }
                        }
                    }
                }
            }
            #open-menu {
                position: absolute;
                @include hor-center();
                bottom: -20px;
                z-index: 99;

                .dblue-dot {
                    width: 9px;
                    height: 9px;
                    background: #004f8a;
                    margin-right: 4px;
                    display: inline-block;
                    @include borderRadius(50%);
                    margin-top: 10px;
                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }
        #menu-popup {
            height: 100vh;
            background: #fff;
            text-align: center;
            position: fixed;
            z-index: 999999;
            top: 0;
            opacity: 1;
            &::before,
            &::after {
                display: none;
            }
            .menu-items {
                padding: 0;

                ul {
                    margin-right: 0;
                    li {
                        margin-top: 35px;
                        margin-bottom: 0;
                        width: 100%;
                        a {
                            font-size: 35px;
                        }
                        &.brand {
                            display: block;
                            // margin-top: 0;
                            width: 122px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
            }
            .latest-recepies,
            .latest-notification {
                display: none;
            }
        }
    }
}
@media (max-width: 475px) {
    .desktop-menu {
        display: none;
    }
    header .mobile-wrapper {
        display: block;
        position: relative !important;
        width: 100%;
        .logo-wrapper {
            display: block;
        }
        .user-toolbar {
            display: block;
        }
    }
}
@media (max-width: 375px) {
    header #menu-popup #close-menu {
        width: 375px;
    }
}
@media (max-width: 320px) {
    header #menu-popup #close-menu {
        width: 320px;
        img {
            bottom: 50px;
        }
    }
}
