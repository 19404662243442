@-webkit-keyframes shake-vertical {
    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }
    80% {
        -webkit-transform: translateY(6.4px);
        transform: translateY(6.4px);
    }
    90% {
        -webkit-transform: translateY(-6.4px);
        transform: translateY(-6.4px);
    }
}
@keyframes shake-vertical {
    0%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    10%,
    30%,
    50%,
    70% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
    20%,
    40%,
    60% {
        -webkit-transform: translateY(8px);
        transform: translateY(8px);
    }
    80% {
        -webkit-transform: translateY(6.4px);
        transform: translateY(6.4px);
    }
    90% {
        -webkit-transform: translateY(-6.4px);
        transform: translateY(-6.4px);
    }
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}
@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}
.rotate-center {
    -webkit-animation: rotate-center 0.6s ease-in-out infinite both;
    animation: rotate-center 0.6s ease-in-out infinite both;
}
@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
