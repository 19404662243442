.second-navigation {
    .page-title {
        h2,.crumb-title {
            font-size: 35px !important;
            margin-top: 22px;
            margin-bottom: 86px;
        }

        a {
            &.back-btn {
                position: absolute;
                @include ver-center();
                top: 0;
                left: 5%;
                padding-left: 60px;

                &::before {
                    content: "";
                    position: absolute;
                    @include ver-center();
                    left: 0;
                    width: 47px;
                    height: 40px;
                    background: url("../../img/icons/back-icon.svg");
                    @include bg-props(contain, center, no-repeat);
                }

                &:hover {
                    color: $red;
                    opacity: 0.8;
                }

                p {
                    font-size: 20px;
                    font-weight: $bold;
                    font-family: $oswald;
                }
            }
        }
    }
}

@media (max-width:1440px) {
    .second-navigation {
        .page-title {
            h2,.crumb-title {
                font-size: 25px !important;
            }
        }
    }
}

@media (max-width: 768px) {
    .second-navigation {
        .page-title {
            h2,.crumb-title {
                // font-size: 30px;
                margin-top: 0;
                margin-bottom: 20px;
            }

            a {
                &.back-btn {
                    top: -70%;
                    width: 34px;
                    height: 28px;
                    padding: 0;

                    &::before {
                        top: 0;
                        width: 30px;
                        height: 20px;
                    }

                    p {
                        display: none;
                    }
                }
            }
        }
    }
}



@media (max-width: 320px) {
    .second-navigation {
        .page-title {
            a {
                &.back-btn {
                    top: 0;
                }
            }
        }
    }
}
