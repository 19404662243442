.red-border {
    border: 2px solid $red;
}
.white-border {
    border: 2px solid #fff;
}
.dblue-border {
    border: 2px solid $dBlue;
}
.red-border {
    border: 2px solid $red;
}
.border-rounded {
    @include borderRadius(30px);
}

a,
button {
    &.blue-txt {
        color: $dBlue;
        font-family: $oswald;
        font-weight: $bold;
        font-size: 18px;
        padding: 10px 35px;
    }
}
a.white-txt {
    color: #fff;
    font-family: $oswald;
    font-weight: $bold;
    font-size: 18px;
    padding: 10px 35px;
}
button {
    &.white-txt {
        color: #fff;
        font-family: $oswald;
        font-weight: $bold;
        font-size: 18px;
        padding: 10px 35px;
    }
}
.invert-hover-b {
    &:hover {
        color: #fff !important;
        background: $dBlue !important;
        border-color: $dBlue !important;
    }
}
.invert-hover-r {
    &:hover {
        color: $dBlue;
        background: #fff;
        border-color: $red;
    }
}
.invert-hover-w {
    &:hover {
        color: $dBlue;
        background: #fff;
        border-color: #fff;
    }
}
.do-btn-w {
    min-width: 238px;
    text-align: center;
    font-size: 18px;
}
.do-btn-b {
    min-width: 238px;
    text-align: center;
    background: $dBlue;
}
@media (max-width: 1024px) {
    .blue-txt {
        font-size: 18px;
    }
}
@media (max-width: 768px) {
    a.white-txt,
    a.blue-txt {
        font-size: 0.9375rem;
    }
}
