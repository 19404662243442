.categories-wrapper {
    background-image: url("../../img/bg-products.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .odd,
    .even {
        padding: 185px 150px;
    }

    .odd {
        div {
            &:first-child {
                order: 1;
            }

            &:nth-child(2) {
                order: 2;
            }
        }

        .product {
            border-left: 2px dashed $dBlue;
        }
    }

    .even {
        div {
            &:first-child {
                order: 2;
            }

            &:nth-child(2) {
                order: 1;
            }
        }

        .product {
            border-right: 2px dashed $dBlue;
        }
    }
}
#product-categories-page {
    .product-hover {
        p {
            min-height: 32px;
            font-size: 16px;
        }
        .title {
            min-height: 32px;
            font-size: 16px !important;
        }
        a {
            display: block;
            text-align: center;
            // position: absolute;
            // @include hor-center();
            // bottom: 0;
        }
        .product-thumbnail {
            height: 100%;
            max-height: 275px;
            object-fit: contain;
        }
    }
}
.category-banner {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 166px;
        background-image: url("../../img/curve.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        z-index: 0;
    }

    .info {
        position: relative;
        z-index: 1;

        h3 {
            font-size: 45px;
            margin-bottom: 18px;
        }

        h4 {
            font-size: 20px;
        }
    }
}

@media (max-width: 1024px) {
    .categories-wrapper {
        .odd,
        .even {
            padding: 100px;
        }
    }
    #product-categories-page {
        .product-hover {
            p {
                min-height: 48px;
            }
            .title {
                min-height: 48px !important;
            }
        }
    }
}

@media (max-width: 768px) {
    .categories-wrapper {
        .odd,
        .even {
            padding: 50px;
        }
    }

    .category-banner {
        &::after {
            height: 150px;
            background-size: contain;
        }

        .info {
            h3 {
                font-size: 35px;
                max-width: 15ch;
                margin: 0 auto 18px auto;
            }

            h4 {
                font-size: 20px;
            }
        }
    }
    #product-categories-page .product-hover .product-thumbnail {
        max-height: 190px;
    }
}

@media (max-width: 425px) {
    .categories-wrapper {
        background-image: url("../../img/bg-products-mobile.png");
        background-size: cover;
        padding-bottom: 35px;
        .odd,
        .even {
            padding: 3px 50px;
            flex-direction: column;
            gap: 30px;

            .product {
                border-bottom: 2px dashed $dBlue;
                padding: 40px 20px !important;
            }
        }

        .odd {
            div {
                &:first-child {
                    order: 2;
                }

                &:nth-child(2) {
                    order: 1;
                }
            }

            .product {
                border-left: unset;
            }
        }

        .even {
            .product {
                border-right: unset;
            }
        }
    }
    #product-categories-page {
        .product-hover {
            p {
                min-height: auto;
            }
            .title {
                min-height: auto !important;
            }
        }
    }
}
