.select2-container--default, .select2-selection--single{
    background-color: unset  !important;
    border: unset !important;
    border-radius: unset !important;
}
.select2-selection__arrow{
    display: none  !important;
}
.select2-container--default{
    width: 100%  !important;
}